import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, styled } from '@mui/material';

const PageTitle = styled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(4)};
`
);

interface IthPageTitleWrapperProps {
  children?: ReactNode;
}

const IthPageTitleWrapper: FC<IthPageTitleWrapperProps> = ({ children }) => {
  return (
    <PageTitle className="MuiPageTitle-wrapper">
      <Container maxWidth="lg">{children}</Container>
    </PageTitle>
  );
};

IthPageTitleWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default IthPageTitleWrapper;