import { FC, useEffect, useState } from 'react';
import { Alert, AlertColor, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, MenuItem, TextField, Typography, styled } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { ContextManager } from 'src/managers/ContextManager';
import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import { QuestionTypes } from 'src/constants/QuestionTypes';
import { Answer, Establishment, Question, QuestionQuestionnaireLegend, QuestionnaireLegend, QuestionnaireSection } from 'src/api/ApiClient';
import { ApiManager } from 'src/managers/ApiManager';
import IthLoadDialog from './IthLoadDialog';
import { BenchmarkingReportPdfGenerator } from 'src/pdf/BenchmarkingReportPdfGenerator';

const TEXT_FILTERS_TITLE: string = "Filtros";
const TEXT_FILTER_CRITERY: string = "Selecione criterio"
const TEXT_DASHBOARD_TITLE: string = "INFORME";
const TEXT_INTRO: string = "El presente informe de resultados realiza un análisis comparativo de los resultados según la muestra establecida por la propia BBDD del aplicativo. Según la selección aplicada, el siguiente informe se ha elaborado a partir de un total de 17 correspondientes a los hoteles registrados que coinciden con los filtros indicados.";

const TEXT_SAMPLE_DESCRIPTION_PREFIX: string = "El presente informe de resultados realiza un análisis comparativo de los resultados según la muestra establecida por la propia BBDD del aplicativo. Según la selección aplicada, el siguiente informe se ha elaborado a partir de un total de";
const TEXT_SAMPLE_DESCRIPTION_SUFIX: string = "hoteles correspondientes a los hoteles registrados que coinciden con los filtros indicados.";


const TEXT_SECTION_TITLE_DIAGNOSTIC_RESUME: string = "Resumen de diagnóstico";
const TEXT_SUSTAINABILITYDEGREE_RESULT: string ="Según el resultado obtenido al realizar el cuestionario de autoevaluación en sostenibilidad hotelera se considera que el grado de sostenibilidad en su establecimiento es";
const TEXT_DEGREEALIGNMENT_RESULT: string ="Del mismo modo, el grado de alineación con los objetivos de desarrollo sostenible es "; 

const TEXT_SECTION_TITLE_SUSTAINABILITYGRADE: string = "Grado de sostenibilidad";
const TEXT_EXPLANATION_SUSTAINABILITYGRADE: string = "Para cada una de las secciones en las que se divide el cuestionario, se presentan dos barras verticales, una indicando la media obtenida por los hoteles de la muestra con los que se compara, y otra el valor actual de su establecimiento.";
  


const TEXT_SECTION_TITLE_ALIGNODSGRADE: string = "Grado de alineación con los ODS";
const TEXT_EXPLANATION_ALIGNODSGRADE: string = "Para cada uno de los ODS a los que afectan las preguntas del cuestionario, se presenta una barra apilada en la que aparece en gris el valor total que puede alcanzar un establecimiento si cumple todas las preguntas que afectan a dicho ODS, y en amarillo el nivel de cumplimiento actual de dicho establecimiento en base a las respuestas indicadas al rellenar el cuestionario.";

const TEXT_SECTION_TITLE_NECESSARYCRITERIA: string = "Criterios necesarios";
const TEXT_EXPLANATION_NECESSARYCRITERIA : string = "En el cuestionario de autoevaluación hotelera hay una serie de cuestiones consideradas criterios necesarios, es decir, aspectos críticos que su establecimiento debe cumplir si quiere contar un grado de sostenibilidad satisfactorio y alineado con los ODS y la Agenda 2030.";

const Odss: string[] = ["Ods1","Ods2","Ods3","Ods4","Ods5","Ods6","Ods7","Ods8","Ods9","Ods10","Ods11","Ods12","Ods13","Ods14","Ods15","Ods16","Ods17"];

const TypographySection = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(20)};` );
const TypographyExplanation = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(18)};` );
const TypographyWeakPointQuestion = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(16)};` );
const TypographyWeakPoinRecomendation = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(14)};` );

interface IthBenchmarkDashboardProps {
  idQuestionnaire?: string;
}

const IthBenchmarkDashboard: FC<IthBenchmarkDashboardProps> = ({
  idQuestionnaire = '',
}) => {
  const [openLoadDialog, setOpenLoadDialog] = useState(false);

  const [filter, setFilter] = useState<Number>(0);
  const [establishmentSameType, setEstablishmentSameType] = useState<boolean>();
  const [establishmentSameLocation, setEstablishmentSameLocation] = useState<boolean>();
  const [establishmentSameAr, setEstablishmentSameAr] = useState<boolean>();
  const [showDashboard, setShowDashboard] = useState<boolean>();
  
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [establishments, setEstablishments] = useState<Establishment[]>([]);
  const [questionnaireLegends, setQuestionnaireLegends] = useState<QuestionnaireLegend[]>([]);
  const [questionnaireSections, setQuestionnaireSections] = useState<QuestionnaireSection[]>([]);
  const apiClient = ApiManager.getApiClientInstance();

 

  let questionsPdf: Question[] = [];
  const pdfGenerator = new BenchmarkingReportPdfGenerator();
  
  /*
   * LOAD INFORMATION
   */
  useEffect(() => {
    setQuestions( ContextManager.questions.filter(q => q.idQuestionnaire == idQuestionnaire) );
    setQuestionnaireLegends( ContextManager.questionnaireLegends.filter(q => q.idQuestionnaire == idQuestionnaire) );
    setQuestionnaireSections( ContextManager.questionnairesSections.filter(s => s.idQuestionnaire == idQuestionnaire && s.idParentSection == null) );
    
    apiClient.apiAnswerGetListByQuestionnaire(idQuestionnaire)
      .then((data: Answer[]) => {
        setAnswers(data ?? []);
      });

    apiClient.apiEstablishmentGetList()
      .then((data: Establishment[]) => {
        setEstablishments(data ?? []);
      });
  }, []);

  /*
   * Filters
   */
  function getFilterCriteria() : any {
    let options: any[] = [];
    options.push(<MenuItem key="1" value="1">Comparar por hoteles por tipología y localización</MenuItem> );
    options.push(<MenuItem key="2" value="2">Comparar con un hotel modelo</MenuItem> );
    return options;
  }

  function getBenchmarkType(): string {
    if (filter == 1) return "COMPARACIÓN POR HOTELES POR TIPOLOGÍA Y LOCALIZACIÓN";
    else {
      if(establishmentSameType) return "COMPARACIÓN POR ESTABLECIMIENTOS DEL MISMO TIPO";
      if(establishmentSameLocation) return "COMPARACIÓN POR ESTABLECIMIENTOS DE LA MISMA LOCALIZACIÓN";
      return "COMPARACIÓN POR ESTABLECIMIENTOS DE LA MISMA COMUNIDAD AUTÓNOMA";
    }
  }

  /*
   * Generate PDFs
   */
  async function generatePdf(): Promise<void> {
    setOpenLoadDialog(true);
    await pdfGenerator.DownloadBasicPdfView(
      ContextManager.establishment.name,
      getBenchmarkType(),
      `${TEXT_SUSTAINABILITYDEGREE_RESULT} ${getAlertText(getDataForSustainabilityResumeMessage())}.`,
      `${TEXT_DEGREEALIGNMENT_RESULT } ${getAlertText(getDataForAligmentOdsResumeMessage())}.`,
      getDegreeAlignmentExplanantion());
    setOpenLoadDialog(false);
  }

  /*
   * Establishment Sample
   */
  function getEstablishmentSample(): Establishment[] {

    let establishmentsFiltered: Establishment[] = [];

    // Filtrar hoteles por criterios de búsqueda
    if(filter == 0) return establishments;
    
    if(filter == 1) {
      if(establishmentSameType) {
        establishmentsFiltered = establishments.filter(e => e.idEstablishmentType == ContextManager.establishment.idEstablishmentType);
      }
      if(establishmentSameLocation) {
        establishmentsFiltered = establishments.filter(e => e.idLocalization == ContextManager.establishment.idLocalization);
      }
      if(establishmentSameAr) {
        establishmentsFiltered = establishments.filter(e => e.idAutonomousRegion == ContextManager.establishment.idAutonomousRegion);
      }
    }
    // Grupo de referencia
    else
    {
      // Obtener los dos mejores de cada sección
      let bestEstablishments: Establishment[] = [];
      questionnaireSections.forEach((s) => {
        let tempEstablisments: Establishment[] = establishments.filter(e => e.id != ContextManager.establishment.id);
        let answersSumBySection: number[] = [];
        for(const e of establishments) {
          let answersEstablishment: Answer[] = answers.filter(a => a.idEstablishment == e.id);
          let answersSum: number = 0;
          answersEstablishment.forEach((a) => {
            let question = questions.filter(q => (q.id == a.idQuestion) && (q.idQuestionnaireSection == s.id));
            if(question.length == 1) answersSum += a.value;
          });
          answersSumBySection.push(answersSum);
        }

        let index = answersSumBySection.indexOf(Math.max(...answersSumBySection));
        bestEstablishments.push(tempEstablisments[index]);
        tempEstablisments.splice(index, 1);
      });

      establishmentsFiltered = [...bestEstablishments.filter(n=>n!==undefined)];
    }

    return establishmentsFiltered;
  }

  function getSampleDescription(): string {
    return `${TEXT_SAMPLE_DESCRIPTION_PREFIX} ${getEstablishmentSample().length} ${TEXT_SAMPLE_DESCRIPTION_SUFIX}`;
  }

  /**
   * Resumen de diagnóstico
   */
  function getDataForSustainabilityResumeMessage(): number[] {
    // Total questions
    const total: number = questions.filter( q => (q.idQuestionType != QuestionTypes.SingleAnswer)).length;
    
    // Total puntuation
    let current: number = 0;
    answers.forEach((a) => {
      let question = questions.filter(q => (q.id == a.idQuestion));
      if(question.length == 1) current += a.value;
    });
    
    // Comparison
    const rest: number = total - current;
    let data: number[] = [current, rest];
    return data;
  }

  function getDataForAligmentOdsResumeMessage(): number[] {
    // Total
    const totalAligment = getTotalAligmentOds();
    let total : number = 0;
    totalAligment.forEach((d) => { total += d; });

    // Current
    const currentAligment = getCurrentAligmentOds();
    let current: number = 0;
    currentAligment.forEach((d) => { current += d; });

    // Result
    const rest: number = total - current;
    let data: number[] = [current, rest];
    return data;
  }

  function getAlertType(results: number[]): AlertColor {
    const average: number = (results[0]*100)/(results[0]+results[1]);
    if(average < 25) return "error";
    else if(average < 50) return "warning";
    else if(average < 75) return "info";
    else if(average <= 100) return "success";
  }

  function getAlertText(results: number[]): string {
    const average: number = (results[0]*100)/(results[0]+results[1]);
    if(average < 25) return "Deficiente";
    else if(average < 50) return "Suficiente";
    else if(average < 75) return "Bueno";
    else if(average <= 100) return "Excelente";
  }
  
  /*
   * Gráfica del Grado de sostenibilidad
   */
  function getSustainabilityGradeLabels(): string [] {
    return questionnaireSections.map(s => s.name);
  }
  
  function getSustainabilityGradeTotals(): any {
    let results: number[] = [];

    // 1. Filtrar hoteles por criterios de búsqueda
    let sampleEstablishment: Establishment[] = getEstablishmentSample().filter(e => e.id != ContextManager.establishment.id);

    // 2. Obtener la media de los hoteles por cada sección
    let sumBySection: number[] = [];
    for(const e of sampleEstablishment) {
      let establishmentAnswers: Answer[] = answers.filter(a => a.idEstablishment == e.id);
      let halfBySectionAndEstablishment: number[] = [];

      questionnaireSections.forEach( (s) => {
        let answersSum: number = 0;
        establishmentAnswers.forEach((a) => {
          let question = questions.filter(q => (q.id == a.idQuestion) && (q.idQuestionnaireSection == s.id));
          if(question.length == 1) answersSum += a.value;
        });
        // total section
        let questionsNumber = questions.filter(x => (x.idQuestionnaireSection == s.id) && (x.idQuestionType != QuestionTypes.SingleAnswer)).length;
        // half
        let half: number = (answersSum*100)/questionsNumber;
        halfBySectionAndEstablishment.push(half);
      });

      for(let i = 0; i < questionnaireSections.length; i++) {
        if(sumBySection.length == 0) sumBySection = [...halfBySectionAndEstablishment];
        else sumBySection[i] += halfBySectionAndEstablishment[i];
      }
    }

    for(let i = 0; i < questionnaireSections.length; i++) {
      let halfBySection: number = sumBySection[i]/sampleEstablishment.length;
      results.push(Number(halfBySection.toFixed(2)));
    }

    return results;
  }

  function getSustainabilityGradeCurrents(): any {
    let sections = questionnaireSections.filter(s => s.idParentSection == null);
    let results: number[] = [];
    sections.forEach((s) => {
      let answersSum: number = 0;
      answers.forEach((a) => {
        let question = questions.filter(q => (q.id == a.idQuestion) && (q.idQuestionnaireSection == s.id));
        if(question.length == 1) answersSum += question[0].value;
      });
      results.push(answersSum);
    });
    return results;
  }

  const chartSustainabilityGradeBarOptions: ApexOptions = {
    chart: {
      id: 'sustainability',
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    xaxis: {
      categories: getSustainabilityGradeLabels(),
    },
    yaxis: {
      decimalsInFloat: 2
      // title: {
      //   text: 'Total - Estado actual'
      // }
    },
  };

  const chartSustainabilityGradeBarSeries = [
    { name: 'Media de los hoteles de la muestra', data: getSustainabilityGradeTotals() }, 
    { name: 'Estado actual', data: getSustainabilityGradeCurrents() }
  ];


  /*
   * Gráfica del Grado de alineamiento con los ODS
   */

  function getDtSampleCurrentValuesForAligmentOdssDegreeChart(): any {
    let results: number[] = [];

    // Recorrer el array de Odss
    Odss.forEach((ods) => {
      //Obtener las legendas que se corresponden con ese ODS
      let questionnaireLegendOds = questionnaireLegends.filter(ql => ql.abbrevation == ods);

      //Para cada una obtener el valor de las preguntas asociadas
      let answersSum: number = 0;
      let totalQuestions: number = 0;
      questionnaireLegendOds.forEach((qlo) => {
        let questionLegend = questionnaireLegends.filter(x => (x.id == qlo.id));
        totalQuestions += questionLegend.length;
        questionLegend.forEach(qlbo => {
          let questionsIds: string[] = qlbo.questionQuestionnaireLegendQuestionnaireLegends.map(qql => qql.idQuestion);
          let answersByOds = answers.filter(a =>  questionsIds.find(qId => qId == a.idQuestion) );
          answersByOds.forEach((a) => {
            let question = questions.filter(q => q.id == a.idQuestion);
            if(question.length == 1) answersSum += a.value;
          });
        });
      });
      let average: number = (answersSum*100)/totalQuestions;
      results.push(Number(average.toFixed(2)));
    });
    return results;
  }

  function getDegreeAlignmentExplanantion(): string {
    let results = getDtSampleCurrentValuesForAligmentOdssDegreeChart();

    let odsHigh: number[] = [];
    let odsMedium: number[] = [];
    let odsLow: number[] = [];

    for (let index = 0; index < results.length; index++) {
      if(results[index] >= 70)  odsHigh.push(index+1);
      else if((results[index] < 70) && (results[index] >= 40))  odsMedium.push(index+1);
      else odsLow.push(index+1);
    }
    let noneOds: string = " ningún ODS ";
    let someOds: string = " los ODS ";
    let prefix: string = "Según el resultado obtenido al realizar el cuestionario de autoevaluación en sostenibilidad hotelera se considera que su establecimiento hotelero tiene una alineación alta con";
    let mediumAlign: string = "una alineación media con ";
    let lowAlign: string = "y una alineación baja con  ";
    let result: string =
      prefix + ((odsHigh.length > 0) ? `${someOds} ${odsHigh.map(o => `${o} `)}` : `${noneOds}`) +
      mediumAlign + ((odsMedium.length > 0) ? `${someOds} ${odsMedium.map(o => `${o} `)}` : `${noneOds}`) +
      lowAlign + ((odsLow.length > 0) ? `${someOds} ${odsLow.map(o => `${o} `)}` : `${noneOds}`) +
      ". Para más información en relación con los ODS prioritarios y posibles líneas de actuación y mejora, consulte la sección Cuestionario > Sostenibilidad > ODS y Agenda 2030.";

    return result;
  }

  function getOdsAlignmentLabels(): string [] {
    return questionnaireLegends.filter(s => s.value == 100).map(s => s.abbrevation);
  }
  
  function getSampleTotalsForAligmentOdssDegreeChart(): any {
    let results: number[] = [];
    // Aquí los totales --> es la media del resto de hoteles

    // 1. Filtrar hoteles por criterios de búsqueda
    let establishments: Establishment[] = getEstablishmentSample();

    // 2. Obtener la media de los hoteles por cada sección
    let sumBySection: number[] = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    for(const e of establishments) {
      // No incluir el establecimiento bajo estudio
      if(e.id == ContextManager.establishment.id) continue;

      let establishmentAnswers: Answer[] = answers.filter(a => a.idEstablishment == e.id);
      let halfBySectionAndEstablishment: number[] = [];

      Odss.forEach((ods) => {
        //Obtener las legendas que se corresponden con ese ODS
        let questionnaireLegendOds = questionnaireLegends.filter(ql => ql.abbrevation == ods);

        //Para cada una obtener el valor de las preguntas asociadas
        let answersSum: number = 0;
        let totalQuestions: number = 0;
        questionnaireLegendOds.forEach((qlo) => {
          let questionLegend = questionnaireLegends.filter(x => (x.id == qlo.id));
          totalQuestions += questionLegend.length;
          questionLegend.forEach(qlbo => {
            // obtener la preguntas con ese ods --> qlbo.questionQuestionnaireLegendQuestionnaireLegends
            let questionsIds: string[] = qlbo.questionQuestionnaireLegendQuestionnaireLegends.map(qql => qql.idQuestion);
            let answersByOds = establishmentAnswers.filter(a =>  questionsIds.find(qId => qId == a.idQuestion) );
            answersByOds.forEach((a) => {
              let question = questions.filter(q => q.id == a.idQuestion);
              if(question.length == 1) answersSum += a.value;
            });
          });
        });
        let average: number = (answersSum*100)/totalQuestions;
        halfBySectionAndEstablishment.push(isNaN(average) ? 0 : average);
      });

      for(let i = 0; i < Odss.length; i++) {
        sumBySection[i] += halfBySectionAndEstablishment[i];
      }
    }

    for(let i = 0; i < Odss.length; i++) {
      let halfBySection: number = sumBySection[i]/establishments.length;
      results.push(Number(halfBySection.toFixed(2)));
    }

    return results;
  }


  function getOdsAlignmentCurrents(): number[] {
    let results: number[] = [];

    let totals: number[] = getTotalAligmentOds();
    let currents: number[] = getCurrentAligmentOds();
    
    for(let i=0; i < totals.length; i++) {
      let dataTotal: number = totals[i];
      let dataCurrent: number = currents[i];

      let half: number = (dataCurrent*100)/dataTotal;
      results.push(Number(half.toFixed(2)));
    }

    return results;
  }

  function getTotalAligmentOds(): number[] {
    let totals: number[] = [];
    Odss.forEach((ods) => {
      let questionnaireLegendOds = ContextManager.questionnaireLegends.filter(ql => ql.abbrevation == ods);
      let questionLegendOds: QuestionQuestionnaireLegend[] = [];
      questionnaireLegendOds.forEach((qlo) => {
        let qs = ContextManager.questions.filter(x => (x.idQuestionnaire == qlo.idQuestionnaire));
        qs.forEach((q) => {
          if(q.questionQuestionnaireLegends.filter(qql => qql.idQuestionnaireLegend == qlo.id).length > 0) {
            questionLegendOds.push(q);
          }
        });
      });
      totals.push(questionLegendOds.length);
    });
    return totals;
  }

  function getCurrentAligmentOds(): number[] {
    let currents: number[] = [];
    Odss.forEach((ods) => {
      let questionnaireLegendOds = questionnaireLegends.filter(ql => ql.abbrevation == ods);
      let answerSum: number = 0;
      questionnaireLegendOds.forEach((qlo) => {
        let qs = ContextManager.questions.filter(x => (x.idQuestionnaire == qlo.idQuestionnaire));
        qs.forEach((q) => {
          if(q.questionQuestionnaireLegends.filter(qql => qql.idQuestionnaireLegend == qlo.id).length > 0) {
            let answer = answers.filter((a) => a.idQuestion == q.id);
            if(answer.length > 0) answerSum += answer[0].value;
          }
        });
      });
      currents.push(answerSum);
    });
    return currents;
  }

  const chartOdsAlignmentOptions: ApexOptions = {
    chart: {
      id: 'odss',
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: getOdsAlignmentLabels(),
    },
    yaxis: {
    },
  };

  const chartOdsAlignmentSeries = [
    { name: 'Media de la muestra', data: getSampleTotalsForAligmentOdssDegreeChart() },
    { name: 'Estado Actual', data: getOdsAlignmentCurrents() }
  ];


  /*
   * Render necessary criteria
   */ 
  function renderNecessaryCriteria() {
    let establishments: Establishment[] = getEstablishmentSample();
    let questionsNecessaryCriteria: Question[] = questions.filter(q => (q.necessaryCriteria == true));

    let controls = questionnaireSections.map((item, index) => {
      return (
        <div key={item.id}>
          <TypographySection variant="h3">{item.name}</TypographySection>
          <br />
          { renderSection(item.id, questionsNecessaryCriteria, establishments) }
        </div>
        );
    });
    return (
      <div>
        <TypographyExplanation>{TEXT_EXPLANATION_NECESSARYCRITERIA}</TypographyExplanation>
        <br/>
        {controls}
      </div>

      );
  }

  function renderSection(sectionId: string, questions: Question[], establishments: Establishment[] ) {
    let questionBySection: Question[] = questions.filter( q => q.idQuestionnaireSection == sectionId);

    let controls = questionBySection.map((q, index) => {
      let answersSum: number = 0;
      for(const e of establishments) {
        let answersByEstablishment: Answer[] = answers.filter(a => a.idEstablishment == e.id);
        for(const a of answersByEstablishment) {
          if(a.idQuestion == q.id) answersSum++;
        }
      }
      let average: number = (answersSum*100)/establishments.length;

      return (
        <div key={q.id}>
          <TypographyExplanation>{q.body}</TypographyExplanation>
          <TypographyExplanation>{`Cumplimiento del ${average.toFixed(2)}%`}</TypographyExplanation>
          <br/>
        </div>
      );
    });
    return (controls);
  }


  /*
   * RENDER COMPONENT
   */
  return (
    <Grid>
      <IthLoadDialog open={openLoadDialog} />
      <Grid>
        <TypographySection variant="h3">{TEXT_FILTERS_TITLE}</TypographySection>
        <br />
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="criteriaType"
          label={TEXT_FILTER_CRITERY}
          value={filter}
          onChange={(e) => { setFilter(Number(e.target.value)) ; setShowDashboard(false); } }
        >
          {getFilterCriteria()}
        </TextField>
        {
          (filter == 1) &&
          <div>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} 
                checked={establishmentSameType} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setEstablishmentSameType(event.target.checked); setShowDashboard(false); }} 
                label="Comparar con establecimientos del mismo tipo" />
              <FormControlLabel control={<Checkbox />} 
                checked={establishmentSameLocation} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setEstablishmentSameLocation(event.target.checked); setShowDashboard(false); }} 
                label="Comparar con establecimientos de la misma localización" />
              <FormControlLabel control={<Checkbox />} 
                checked={establishmentSameAr} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setEstablishmentSameAr(event.target.checked); setShowDashboard(false); }} 
                label="Comparar con establecimientos de la misma comunidad autónoma" />
            </FormGroup>
          </div>
        }
        <Button
          variant="contained" sx={{ mt: 3, mb: 2 }} 
          onClick={() => { setShowDashboard(true) }}
          >
            Visualizar informe
        </Button>
      </Grid>
      
      {
        showDashboard &&
        <div>
          <Divider/>
          <br/>

          <TypographySection variant="h3">{TEXT_DASHBOARD_TITLE}</TypographySection>
          <br />

          <Button
            variant="contained"
            sx={{ mb: 2, justifyContent: "auto" }}
            onClick={generatePdf}
            endIcon={<SendIcon />}
            >
              Generar PDF
          </Button>
          <br />

          <TypographyExplanation>{getSampleDescription()}</TypographyExplanation>
          <br />

          <TypographySection variant="h3">{TEXT_SECTION_TITLE_DIAGNOSTIC_RESUME}</TypographySection>
          <br/>
          <Alert severity={getAlertType(getDataForSustainabilityResumeMessage())} > 
              {`${TEXT_SUSTAINABILITYDEGREE_RESULT} ${getAlertText(getDataForSustainabilityResumeMessage())}.`}
          </Alert>
          <br/>
          <Alert severity={getAlertType(getDataForAligmentOdsResumeMessage())} > 
            {`${TEXT_DEGREEALIGNMENT_RESULT } ${getAlertText(getDataForAligmentOdsResumeMessage())}.`}
          </Alert>
          <br/>

          <TypographySection variant="h3">{TEXT_SECTION_TITLE_SUSTAINABILITYGRADE}</TypographySection>
          <br />
          <TypographyExplanation>{TEXT_EXPLANATION_SUSTAINABILITYGRADE}</TypographyExplanation>
          <br />
          <div id="sustainability">
            <Chart
              height={350}
              options={chartSustainabilityGradeBarOptions}
              series={chartSustainabilityGradeBarSeries}
              type="bar"
            />
          </div>
          
          <Divider />
          <br />

          <TypographySection variant="h3">{TEXT_SECTION_TITLE_ALIGNODSGRADE}</TypographySection>
          <br />
          <TypographyExplanation>{TEXT_EXPLANATION_ALIGNODSGRADE}</TypographyExplanation>
          <br />
          <div id="odss">
            <Chart
              height={350}
              options={chartOdsAlignmentOptions}
              series={chartOdsAlignmentSeries}
              type="radar"
              />
          </div>
          
          <Divider />
          <br />

          <TypographySection variant="h3">{TEXT_SECTION_TITLE_NECESSARYCRITERIA}</TypographySection>
          <br />
          {renderNecessaryCriteria()}
        </div>
      }

      
    </Grid>
  );
};

IthBenchmarkDashboard.propTypes = {
};

export default IthBenchmarkDashboard;
