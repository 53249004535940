import { FC, useEffect, useState } from 'react';
import { Alert, AlertColor, Button, Divider, Grid,Typography, styled } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { ContextManager } from 'src/managers/ContextManager';
import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import { QuestionTypes } from 'src/constants/QuestionTypes';
import { Answer, Question, QuestionQuestionnaireLegend, QuestionnaireLegend } from 'src/api/ApiClient';
import { ApiManager } from 'src/managers/ApiManager';
import { SustainabilityReportPdfGenerator } from 'src/pdf/SustainabilityReportPdfGenerator';
import IthLoadDialog from './IthLoadDialog';
import { QuestionnaireTypes } from 'src/constants/QuestionnaireTypes';

const TEXT_SECTION_TITLE_DIAGNOSTIC_RESUME: string = "Resumen de diagnóstico";
const TEXT_SUSTAINABILITYDEGREE_RESULT: string ="Según el resultado obtenido al realizar el cuestionario de autoevaluación en sostenibilidad hotelera se considera que el grado de sostenibilidad en su establecimiento es";
const TEXT_DEGREEALIGNMENT_RESULT: string ="Del mismo modo, el grado de alineación con los objetivos de desarrollo sostenible es "; 

const TEXT_SECTION_TITLE_SUSTAINABILITYGRADE: string = "Grado de sostenibilidad";
const TEXT_EXPLANATION_SUSTAINABILITYGRADE: string = "Para cada una de las secciones en las que se divide el cuestionario, se presentan dos barras horizontales, una en azul indicando la puntación total que puede llegar a alcanzar un establecimiento en base a la puntuación asignada a cada respuesta, y en verde el valor actual de dicho establecimiento en base a las respuestas indicadas al rellenar el cuestionario.";
const TEXT_SECTION_TITLE_ALIGNODSGRADE: string = "Grado de alineación con los ODS";
const TEXT_EXPLANATION_ALIGNODSGRADE: string = "A continuación se presenta un gráfico radar que indica el grado de cumplimiento para cada uno de los ODS a los que afectan las preguntas del cuestionario. Entre más se acerquen los puntos a la parte exterior del radar, mayor será el nivel de cumpliento para ese ODS, haciendo que el tamaño del área azul recogido por la unión de los diferentes puntos sea mayor. ";
const TEXT_SECTION_TITLE_WEAKPOINTS: string = "Puntos débiles y recomendaciones";
const TEXT_EXPLANATION_WEAKPOINTS: string = "A continuación se listan aquellas preguntas en las que existe un mayor orden de mejora en base a sus respuestas en el cuestionario de autoevaluación.";
const Odss: string[] = ["Ods1","Ods2","Ods3","Ods4","Ods5","Ods6","Ods7","Ods8","Ods9","Ods10","Ods11","Ods12","Ods13","Ods14","Ods15","Ods16","Ods17"];

const TypographySection = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(20)};` );
const TypographyExplanation = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(18)};` );
const TypographyWeakPointQuestion = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(16)};` );
const TypographyWeakPoinRecomendation = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(14)};` );

interface IthDiagnosticDashboardProps {
  idQuestionnaire?: string;
}

const IthDiagnosticDashboard: FC<IthDiagnosticDashboardProps> = ({
  idQuestionnaire = '',
}) => {
  const [openLoadDialog, setOpenLoadDialog] = useState(false);

  const [answers, setAnswers] = useState<Answer[]>([]);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [questionnaireLegends, setQuestionnaireLegends] = useState<QuestionnaireLegend[]>([]);
  const apiClient = ApiManager.getApiClientInstance();

  let questionsPdf: Question[] = [];
  const pdfGenerator = new SustainabilityReportPdfGenerator();
  
  /*
   * LOAD INFORMATION
   */
  useEffect(() => {
    setQuestions( ContextManager.questions.filter(q => q.idQuestionnaire == idQuestionnaire) );
    setQuestionnaireLegends( ContextManager.questionnaireLegends.filter(q => q.idQuestionnaire == idQuestionnaire) );
    apiClient.apiAnswerGetListByQuestionnaireAndEstablishment(idQuestionnaire, ContextManager.establishment.id)
      .then((data: Answer[]) => {
        setAnswers(data ?? []);
      });
  }, []);

  /*
   * Generate PDFs
   */
  async function generatePdf(): Promise<void> {
    setOpenLoadDialog(true);
    await pdfGenerator.DownloadBasicPdfView(
      ContextManager.establishment.name, 
      (idQuestionnaire == QuestionnaireTypes.Sustainability) ? "TEST DE AUTOEVALUACIÓN EN SOSTENIBILIDAD HOTELERA" : "TEST DE AUTOEVALUACIÓN EN DESPERDICIO ALIMENTARIO",
      questionsPdf,
      `${TEXT_SUSTAINABILITYDEGREE_RESULT} ${getAlertText(getDataForSustainabilityResumeMessage())}.`,
      `${TEXT_DEGREEALIGNMENT_RESULT } ${getAlertText(getDataForAligmentOdsResumeMessage())}.`);
    setOpenLoadDialog(false);
  }

  /**
   * Resumen de diagnóstico
   */
  function getDataForSustainabilityResumeMessage(): number[] {
    // Total questions
    const total: number = questions.filter( q => (q.idQuestionType != QuestionTypes.SingleAnswer)).length;
    
    // Total puntuation
    let current: number = 0;
    answers.forEach((a) => {
      let question = questions.filter(q => (q.id == a.idQuestion));
      if(question.length == 1) current += a.value;
    });
    
    // Comparison
    const rest: number = total - current;
    let data: number[] = [current, rest];
    return data;
  }

  function getDataForAligmentOdsResumeMessage(): number[] {
    // Total
    const totalAligment = getTotalAligmentOds();
    let total : number = 0;
    totalAligment.forEach((d) => { total += d; });

    // Current
    const currentAligment = getCurrentAligmentOds();
    let current: number = 0;
    currentAligment.forEach((d) => { current += d; });

    // Result
    const rest: number = total - current;
    let data: number[] = [current, rest];
    return data;
  }

  function getAlertType(results: number[]): AlertColor {
    const average: number = (results[0]*100)/(results[0]+results[1]);
    if(average < 25) return "error";
    else if(average < 50) return "warning";
    else if(average < 75) return "info";
    else if(average <= 100) return "success";
  }

  function getAlertText(results: number[]): string {
    const average: number = (results[0]*100)/(results[0]+results[1]);
    if(average < 25) return "Deficiente";
    else if(average < 50) return "Suficiente";
    else if(average < 75) return "Bueno";
    else if(average <= 100) return "Excelente";
  }

  /*
   * Gráfica del Grado de sostenibilidad
   */
  function getSustainabilityGradeLabels(): string [] {
    return ContextManager.questionnairesSections.filter(s => s.idQuestionnaire == idQuestionnaire && s.idParentSection == null).map(s => s.name);
  }
  
  function getSustainabilityGradeTotals(): number[] {
    let sections = ContextManager.questionnairesSections.filter(s => s.idQuestionnaire == idQuestionnaire && s.idParentSection == null);
    let results: number[] = [];
    sections.forEach((s) => {
      let total = questions.filter( q => (q.idQuestionnaireSection == s.id) && (q.idQuestionType != QuestionTypes.SingleAnswer)).length;
      results.push(total);
    });
    return results;
  }

  function getSustainabilityGradeCurrents(): number[] {
    let sections = ContextManager.questionnairesSections.filter(s => s.idQuestionnaire == idQuestionnaire && s.idParentSection == null);
    let results: number[] = [];
    sections.forEach((s) => {
      let answersSum: number = 0;
      answers.forEach((a) => {
        let question = questions.filter(q => (q.id == a.idQuestion) && (q.idQuestionnaireSection == s.id));
        if(question.length == 1) answersSum += a.value;
      });
      results.push(answersSum);
    });
    return results;
  }

  const chartSustainabilityGradeBarOptions: ApexOptions = {
    chart: {
      id: 'sustainability',
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    xaxis: {
      categories: getSustainabilityGradeLabels(),
    },
    yaxis: {
      // title: {
      //   text: 'Total - Estado actual'
      // }
    },
  };

  const chartSustainabilityGradeBarSeries = [
    { name: 'Totales', data: getSustainabilityGradeTotals() }, 
    { name: 'Estado actual', data: getSustainabilityGradeCurrents() }
  ];


  /*
   * Gráfica del Grado de alineamiento con los ODS
   */
  function getOdsAlignmentLabels(): string [] {
    return questionnaireLegends.filter(s => s.value == 100).map(s => s.abbrevation);
  }

  function getOdsAlignmentCurrents(): number[] {
    let results: number[] = [];

    let totals: number[] = getTotalAligmentOds();
    let currents: number[] = getCurrentAligmentOds();
    
    for(let i=0; i < totals.length; i++) {
      let dataTotal: number = totals[i];
      let dataCurrent: number = currents[i];

      let half: number = (dataCurrent*100)/dataTotal;
      results.push(Number(half.toFixed(2)));
    }

    return results;
  }

  function getTotalAligmentOds(): number[] {
    let totals: number[] = [];
    Odss.forEach((ods) => {
      let questionnaireLegendOds = ContextManager.questionnaireLegends.filter(ql => ql.abbrevation == ods);
      let questionLegendOds: QuestionQuestionnaireLegend[] = [];
      questionnaireLegendOds.forEach((qlo) => {
        let qs = ContextManager.questions.filter(x => (x.idQuestionnaire == qlo.idQuestionnaire));
        qs.forEach((q) => {
          if(q.questionQuestionnaireLegends.filter(qql => qql.idQuestionnaireLegend == qlo.id).length > 0) {
            questionLegendOds.push(q);
          }
        });
      });
      totals.push(questionLegendOds.length);
    });
    return totals;
  }

  function getCurrentAligmentOds(): number[] {
    let currents: number[] = [];
    Odss.forEach((ods) => {
      let questionnaireLegendOds = questionnaireLegends.filter(ql => ql.abbrevation == ods);
      let answerSum: number = 0;
      questionnaireLegendOds.forEach((qlo) => {
        let qs = ContextManager.questions.filter(x => (x.idQuestionnaire == qlo.idQuestionnaire));
        qs.forEach((q) => {
          if(q.questionQuestionnaireLegends.filter(qql => qql.idQuestionnaireLegend == qlo.id).length > 0) {
            let answer = answers.filter((a) => a.idQuestion == q.id);
            if(answer.length > 0) answerSum += answer[0].value;
          }
        });
      });
      currents.push(answerSum);
    });
    return currents;
  }

  const chartOdsAlignmentOptions: ApexOptions = {
    chart: {
      id: 'odss',
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: getOdsAlignmentLabels(),
    },
    yaxis: {
      title: {
        text: 'Totales vs Estado actual'
      }
    },
  };

  const chartOdsAlignmentSeries = [
    { name: 'Estado Actual', data: getOdsAlignmentCurrents() }
  ];

  /*
   * Puntos débiles
   */

  function renderWeakPointQuestions(): JSX.Element {
    let controls: any = [];
    questions.forEach((q) => {
      let currentAnswerArray: Answer[] = answers.filter(x => x.idQuestion == q.id);
      
      let currentAnswer: Answer = null;
      if(Array.isArray(currentAnswerArray)) currentAnswer = currentAnswerArray[0];
      let paintQuestion: boolean = false;
      if(currentAnswer == null) paintQuestion = true;
      if(currentAnswer != null) {
        if(currentAnswer.value == 0) paintQuestion = true;
      }
      if(q.questionQuestionInfo == null) paintQuestion = false;
      if(paintQuestion) {
        let c: any = renderQuestion(q);
        controls.push(c);
        questionsPdf.push(q);
      }
    });

    if(controls.length == 0){
      return(<div><TypographyWeakPointQuestion variant="h3"><br/>No tiene puntos débiles que resaltar.</TypographyWeakPointQuestion></div>)
    }
    else {
      return (<div>{controls}</div>)
    }

  }

  function renderQuestion(question: Question) {
    return(
      <div>
        <br />
        <TypographyWeakPointQuestion variant="h3">{question.body}</TypographyWeakPointQuestion>
        <TypographyWeakPoinRecomendation><div dangerouslySetInnerHTML={{ __html: question.questionQuestionInfo.body }} /></TypographyWeakPoinRecomendation>
      </div>
    );
  }

  /*
   * RENDER COMPONENT
   */
  return (
    <Grid>
      <IthLoadDialog open={openLoadDialog} />
      <Button
        variant="contained"
        sx={{ mb: 2, justifyContent: "auto" }}
        onClick={generatePdf}
        endIcon={<SendIcon />}
        >
          Generar PDF
      </Button>
      <TypographySection variant="h3">{TEXT_SECTION_TITLE_DIAGNOSTIC_RESUME}</TypographySection>
      <br/>
      <Alert severity={getAlertType(getDataForSustainabilityResumeMessage())} > 
          {`${TEXT_SUSTAINABILITYDEGREE_RESULT} ${getAlertText(getDataForSustainabilityResumeMessage())}.`}
      </Alert>
      <br/>
      <Alert severity={getAlertType(getDataForAligmentOdsResumeMessage())} > 
        {`${TEXT_DEGREEALIGNMENT_RESULT } ${getAlertText(getDataForAligmentOdsResumeMessage())}.`}
      </Alert>
      <br/>

      <TypographySection variant="h3">{TEXT_SECTION_TITLE_SUSTAINABILITYGRADE}</TypographySection>
      <br />
      <TypographyExplanation>{TEXT_EXPLANATION_SUSTAINABILITYGRADE}</TypographyExplanation>
      <br />
      <div id="sustainability">
        <Chart
          height={350}
          options={chartSustainabilityGradeBarOptions}
          series={chartSustainabilityGradeBarSeries}
          type="bar"
        />
      </div>
      
      <Divider />
      <br />
      <TypographySection variant="h3">{TEXT_SECTION_TITLE_ALIGNODSGRADE}</TypographySection>
      <br />
      <TypographyExplanation>{TEXT_EXPLANATION_ALIGNODSGRADE}</TypographyExplanation>
      <br />
      <div id="odss">
        <Chart
          height={350}
          options={chartOdsAlignmentOptions}
          series={chartOdsAlignmentSeries}
          type="radar"
          />
      </div>
      
      <Divider />
      <br />
      <TypographySection variant="h3">{TEXT_SECTION_TITLE_WEAKPOINTS}</TypographySection>
      <br />
      <TypographyExplanation>{TEXT_EXPLANATION_WEAKPOINTS}</TypographyExplanation>
      {renderWeakPointQuestions()}
    </Grid>
    
  );
};

IthDiagnosticDashboard.propTypes = {
};

export default IthDiagnosticDashboard;
