import { FC, useEffect, useState } from 'react';
import { 
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  styled, 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ContextManager } from 'src/managers/ContextManager';
import IthSimpleDialog from './IthSimpleDialog';
import SuspenseLoader from './SuspenseLoader';
import { ApiManager } from 'src/managers/ApiManager';
import { Answer, ApiException, Establishment, IEstablishment, Question, QuestionnaireSection } from 'src/api/ApiClient';
import { QuestionTypes } from 'src/constants/QuestionTypes';

const CONST_RECOMMENDATIONS : string = "Recomendaciones";
const CONST_NO_RECOMMENDATIONS: string = "No hay recomendaciones asignadas a esta pregunta";

const TypographySection = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(18)};` );
const TypographySubsection = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(17)};` );
const TypographyQuestion = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(16)};` );
const TypographyQuestionOptions = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(15)};` );

interface IthQuestionnaireProps {
  idQuestionnaire?: string;
}

const IthQuestionnaire: FC<IthQuestionnaireProps> = ({
  idQuestionnaire = '',
}) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string>("")
  const [body, setBody] = useState<string>("")
  const handleClose = () => { setOpen(false); };
  const handleClickOpen = (title, body) => { setOpen(true); setTitle(title); setBody(body); };

  const [answers, setAnswers] = useState<Answer[]>([]);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const apiClient = ApiManager.getApiClientInstance();

  const showIthSimpleDialogRecommendations = (question: Question) => {
    if(question.questionQuestionInfo != null) {
      setTitle(question.questionQuestionInfo.title);
      setBody(question.questionQuestionInfo.body);
    }
    else {
      setTitle(CONST_RECOMMENDATIONS);
      setBody(CONST_NO_RECOMMENDATIONS);
    }
    setOpen(true);
   }

  /*
   * LOAD INFORMATION
   */
  useEffect(() => {
    apiClient.apiAnswerGetListByQuestionnaireAndEstablishment(idQuestionnaire, ContextManager.establishment.id)
      .then((data: Answer[]) => {
        setAnswers(data ?? []);
      });
  }, []);

  /*
   * SAVE ANSWER
   */
  const saveAnswer = async (question, value) => {
    event.preventDefault();
    setShowProgress(true);

    let index: number = answers.findIndex(a => a.idQuestion == question.id);
    let answer: Answer = null;
    let action: Promise<boolean> = null;

    if(index == -1) {
      answer = new Answer({
        id: ContextManager.getGUID(),
        idQuestion: question.id,
        idQuestionnaire: idQuestionnaire,
        idEstablishment: ContextManager.establishment.id,
        value: value
      });
      action = apiClient.apiAnswerAdd(answer);
    }
    else {
      answer = answers[index];
      answer.value = (value != '') ? value : 'null';
      action = apiClient.apiAnswerUpdate(answer);
    }

    action
      .then(() => { if(index == -1) answers.push(answer); })
      .catch((e: ApiException) => { handleClickOpen("Error", e.response); })
      .finally(() => { setShowProgress(false); });
  };

  function getAnswerValueByQuestionId(idQuestion: string): number {
    let index: number = answers.findIndex(a => a.idQuestion == idQuestion);

    let result: number;
    if(index == -1) result = -1;
    else result = answers[index].value;

    return result;
  }

  /**
     * RENDER QUESTIONNAIRE
     * Método que permite renderizar el cuestonario. Realiza una cadena de llamadas jerárquicas como sigue:
     *  renderSectionsFirstLevel
     *    renderSectionsSecondLevel
     *      renderQuestionsBySection
     *        renderQuestionByType
     * @returns Los controles que se deben renderizar en pantalla
     */
  function renderSectionsFirstLevel() {
    let controls =  ContextManager.questionnairesSections.filter(s => s.idQuestionnaire == idQuestionnaire && s.idParentSection == null)
        .map((item, index) => {
          return (
            <div key={item.id}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <TypographySection variant="h3">{item.name}</TypographySection>
                </AccordionSummary>
                <AccordionDetails>
                  {renderSectionsSecondLevel(item.id)}
                </AccordionDetails>
              </Accordion>
              <Divider />
            </div>
            );
        });
    return controls;
  }

  function renderSectionsSecondLevel(firstLevelSectionId: string): JSX.Element[] {
    let subsections: QuestionnaireSection[] = 
      ContextManager.questionnairesSections.filter(s => 
          s.idQuestionnaire == idQuestionnaire && 
          s.idParentSection == firstLevelSectionId) 
          ?? [];
    
    // Si no existen subsecciones obtenemos las preguntas
    if(subsections.length == 0) {
      return renderQuestionsBySection(idQuestionnaire, firstLevelSectionId);
    }

    // En caso contrario obtenemos las preguntas de la subsección
    let controls = 
      subsections.map((subsection) => {
        return (
          <div key={subsection.id} className={"itemContent"}>
            <TypographySubsection variant='h3'>{subsection.name}</TypographySubsection>
            { renderQuestionsBySection(idQuestionnaire, subsection.id) }
          </div>
        );
      });
    return controls;
  }

  function renderQuestionsBySection(idQuestionnaire: string, subsectionId: string): JSX.Element[] {
    let controls = ContextManager.questions
        .filter(q => (q.idQuestionnaireSection == subsectionId) && (q.idParentQuestion == null) && (q.idQuestionnaire == idQuestionnaire) )
        .map((question) => { return renderQuestionByType(question); });
    return controls;
  }

  function renderQuestionByType(question: Question): JSX.Element {
    switch (question.idQuestionType) {
      case QuestionTypes.SingleAnswer: 
        return renderSingleAnswer(question);
      case QuestionTypes.ConditionalAnswers:
        return renderConditionalAnswers(question);
      case QuestionTypes.MultipleAnswers:
        return renderMultipleAnswers(question);
      default:
        return null;
    }
  }

  function renderSingleAnswer(question: Question): JSX.Element{
    return (
      <div>
        <FormControlLabel control={<Checkbox defaultChecked
             checked={(getAnswerValueByQuestionId(question.id) == 1) ? true : false}
             // value={false}
            onChange={(e, checked) => saveAnswer(question, checked) }
            inputProps={{ 'aria-label': 'controlled' }}
          />} 
          label={<TypographyQuestionOptions>{question.body}</TypographyQuestionOptions>} />
          { renderAnswerLegends(question) }
        <br />
      </div>
    );
  }

  function renderConditionalAnswers(question: Question): JSX.Element {
    let controls = 
      ContextManager.questions.filter(q => q.idParentQuestion == question.id)
        .map((childQuestion) => { return (this.renderSingleAnswer(childQuestion)); });

    return ( 
      <div>
        <FormControlLabel 
          value={question.id} 
          control={
            <Checkbox 
              defaultChecked={this.getQuestionAnswerAsBooleanFromState(question)}
              onChange={(e, checked) => {
                // let condChecked: Question[] = [];
                // if(checked) {
                //   condChecked = this.state.conditionalsChecked.filter(x => x);
                //   condChecked.push(question);
                // }
                // else {
                //   condChecked = this.state.conditionalsChecked.filter(x => x.IthQuestionId != question.IthQuestionId);
                // }
                // this.setState({ conditionalsChecked: condChecked });
                // this.saveAnswer(question, checked ? "true" : "false");
              }} 
            />
          } 
          label={<TypographyQuestionOptions>{question.body}</TypographyQuestionOptions>} 
        /> 
        <br/>
        {
          // (this.state.conditionalsChecked.filter(x => x.IthQuestionId == question.IthQuestionId).length > 0)
          // &&
          // <div>
          //   <Label className={styles.subTitle}>{question.IthQuestionTypeTitle}</Label>
          //   {controls}
          // </div>
        }
      </div>
    );
  }

  function renderMultipleAnswers(question: Question): JSX.Element {
    let options: any[] = []
    ContextManager.questions.filter(q => q.idParentQuestion == question.id)
      .map((childQuestion) => { 
        options.push(
          <FormControlLabel 
            value={childQuestion.value} 
            control={<Radio />} 
            label={<TypographyQuestionOptions>{childQuestion.body}</TypographyQuestionOptions>} /> 
        ); 
      });
    
    let questionTexParts: string[] = question.body.split("<br/>");
    let disclaimer: string = null;
    let questionText: string = null;
    if(questionTexParts.length > 1) {
      questionText = questionTexParts[0];
      disclaimer = questionTexParts[1];
    }
    else questionText = question.body;
    
    return (
      <div>
        <br />
        <TypographyQuestion variant='h4'>{questionText}</TypographyQuestion>
        { (disclaimer != null) && <TypographyQuestion>{disclaimer}</TypographyQuestion> }
        <Grid key={ContextManager.getGUID()} container direction="column" justifyContent="center" alignItems="flex-start">
          <Grid item xs={2}>
            <Button
              variant="contained" sx={{ mt: 3, mb: 2 }}
              onClick={() => { showIthSimpleDialogRecommendations(question); }} >
                {CONST_RECOMMENDATIONS}
            </Button>
          </Grid>
          <Grid item xs={10}>
            { renderAnswerLegends(question) }
          </Grid>
        </Grid>
        
        <RadioGroup
          id={question.id}
          aria-labelledby="controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          // defaultValue={getAnswerValueByQuestionId(question.id)}
          value={getAnswerValueByQuestionId(question.id)}
          onChange={(e, value) => { saveAnswer(question, value); } }
        >
          {options}
        </RadioGroup>
        <br />
      </div>
    );
  }

  function renderAnswerLegends(question: Question): JSX.Element[] {
      let controls = question.questionQuestionnaireLegends
      .map((questionLegend) => { 
        let questionnairelegend = ContextManager.questionnaireLegends.filter(l => l.id == questionLegend.idQuestionnaireLegend).shift();
        return (
          <Tooltip key={ContextManager.getGUID()} title={questionnairelegend.name}>
            <img src={`/static/images/${questionnairelegend.imageName}`} width={questionnairelegend.value} height={questionnairelegend.value} />
          </Tooltip>
          
        );
      });
    return controls;
  }


  return (
    <Container maxWidth="lg">
      {showProgress && <SuspenseLoader /> }
      <IthSimpleDialog
        open={open}
        title={title}
        body={body}
        onClose={handleClose}
      />

      {renderSectionsFirstLevel()}
    </Container>
  );
}

export default IthQuestionnaire;
