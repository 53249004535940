import { Helmet } from 'react-helmet-async';

import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  styled,
  Typography,
} from '@mui/material';
import IthPageTitleWrapper from 'src/components/IthPageTitleWrapper';
import IthPageTitle from 'src/components/IthPageTitle';
import IthFooter from 'src/components/IthFooter';
import IthQuestionnaire from 'src/components/IthQuestionnaire';
import { ContextManager } from 'src/managers/ContextManager';
import { QuestionnaireTypes } from 'src/constants/QuestionnaireTypes';
import IthDiagnosticDashboard from 'src/components/IthDiagnosticDashboard';

const TEXT_TITLE: string = "Informe de diagnóstico de desperdicio alimentario";
const TEXT_SUBTITLE: string = "En esta sección podrá ver un informe con el diagnóstico de su establecimiento en base a las respuestas proporcionadas en el cuestionario de la sección.";

function FoodwasteDiagnosticPage() {
  
  return (
    <>
      <Helmet>
        <title>Informe de diagnóstico de desperdicio alimentario</title>
      </Helmet>
      <IthPageTitleWrapper>
        <IthPageTitle
          heading={TEXT_TITLE}
          subHeading={TEXT_SUBTITLE}
          buttonText=''
          body=""
          
        />
      </IthPageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title={TEXT_TITLE} />
              <Divider />
              <CardContent>
                <IthDiagnosticDashboard idQuestionnaire={QuestionnaireTypes.FoodWaste}></IthDiagnosticDashboard>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <IthFooter />
    </>
  );
}

export default FoodwasteDiagnosticPage;
