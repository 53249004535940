import { Helmet } from 'react-helmet-async';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material';
import IthPageTitleWrapper from 'src/components/IthPageTitleWrapper';
import IthPageTitle from 'src/components/IthPageTitle';
import IthFooter from 'src/components/IthFooter';
import IthSustainabilityMemoryForm from 'src/components/IthSustainabilityMemoryForm';

const PAGE_HEADING: string = "Memoria de sostenibilidad";
const PAGE_SUBHEADING: string = `
Una memoria de sostenibilidad, también conocida como informe de sostenibilidad o informe de responsabilidad social corporativa (RSC), es un documento que una cadena hotelera o hotel independiente puede elaborar para comunicar de manera transparente sus prácticas y desempeño en aspectos ambientales, sociales y económicos. La estructura de una memoria de sostenibilidad puede variar según la empresa y sus prioridades, pero generalmente sigue una estructura similar. A continuación, encontrará una guía básica donde crear la memoria de sostenibilidad de su establecimiento.
<br/><br/>En esta sección encontrará la estructura de los apartados esenciales que deben figurar en una memoria de sostenibilidad. Una vez cumplimentados los espacios habilitados para la información de su establecimiento podrá descargar el documento para su posterior edición y maquetación.
`;
const BUTTON_TEXT: string = "Consejos para la cumplimentación";
const CARD_HEADER: string = "Formulario para la creación de la memoria de sostenibilidad";

let CONST_BIBLIOGRAPHIC_REFERENCES_CONTENT: string = `
      <span>
        <ul>
          <li>Utilizar un lenguaje claro y accesible para llegar a una audiencia amplia.</li>
          <li>Ser transparente acerca de los logros y desafíos. La honestidad contribuye a la credibilidad.</li>
          <li>Proporcionar datos cuantitativos que demuestren el impacto real de las iniciativas sostenibles implementadas en el establecimiento o cadena hotelera.</li>
          <li>Incorporar gráficos, imágenes y vídeos para hacer la información más visual y fácil de entender. Se recomienda la maquetación del documento una vez cumplimentados todos los campos.</li>
          <li>Contextualización del documento en el marco normativo. Cumplir con los estándares y marcos normativos relevantes, puede incluir estándares internacionales de sostenibilidad, regulaciones locales y otras normativas sectoriales.</li>
          <li>Actualizar la memoria de sostenibilidad anualmente para mostrar el progreso continuo y el avance en los objetivos. Este punto refleja el compromiso continuo del hotel o la cadena hotelera con la sostenibilidad.</li>
          <li>Realizar encuestas para obtener retroalimentación sobre la percepción de la sostenibilidad en el hotel o en los hoteles de la cadena. Utilizar esta información para mejorar las futuras memorias de sostenibilidad.</li>
        </ul>
      </span>
    `;  

function SustainabilityMemoryPage() {
  return (
    <>
      <Helmet>
        <title>Memoria de sostenibilidad</title>
      </Helmet>
      <IthPageTitleWrapper>
        <IthPageTitle
          heading={PAGE_HEADING}
          subHeading={PAGE_SUBHEADING}
          buttonText={BUTTON_TEXT}
          body={CONST_BIBLIOGRAPHIC_REFERENCES_CONTENT}
        />
      </IthPageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title={CARD_HEADER} />
              <Divider />
              <CardContent>
                <IthSustainabilityMemoryForm />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      
      <IthFooter />
    </>
  );
}

export default SustainabilityMemoryPage;
