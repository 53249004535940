import PropTypes from 'prop-types';
import { 
  Dialog, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
} from '@mui/material';
import SuspenseLoader from './SuspenseLoader';

function IthLoadDialog(props) {
  const { open } = props;

  return (
    <Dialog maxWidth="lg" open={open}>
      <DialogTitle id="alert-dialog-title">
        Procesando ...
      </DialogTitle>
      <DialogContent>
          <DialogContentText width={100} height={100} id="alert-dialog-description">
            <SuspenseLoader />
          </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

IthLoadDialog.propTypes = {
  open: PropTypes.bool.isRequired
};

export default IthLoadDialog;
