import { useContext, useEffect, useState } from 'react';
import { 
  Box, 
  Button, 
  Checkbox, 
  Container,
  FormControlLabel,
  MenuItem, 
  Switch, 
  TextField, 
} from '@mui/material';

import { ContextManager } from 'src/managers/ContextManager';
import { ApiManager } from 'src/managers/ApiManager';
import { ApiException, Establishment, IEstablishment } from 'src/api/ApiClient';
import IthLoadDialog from './IthLoadDialog';
import IthSimpleDialog from './IthSimpleDialog';
import { useNavigate } from 'react-router';

const DIALOG_TITLE_ERROR : string = "Error";
const DIALOG_TITLE_CREATION_SUCCESS : string = "Creación correcta";
const DIALOG_TITLE_UPDATE_SUCCESS : string = "Actualización correcta";
const DIALOG_BODY_CREATION_SUCCESS : string = "Gracias por crear su establecimiento. A continuación la aplicaicón le pedirá que acceda de nuevo con su usuario para poder acceder al resto de funcionalidad.";
const DIALOG_BODY_UPDATE_SUCCESS : string = "Los datos de su establecimiento se han actualizado correctamente.";

const LABEL_TEXT_ITHPARTNER : string = "Eres socio de ITH";
const LABEL_TEXT_LOPD : string = "Usted consiente, a través de la marcación de la presente casilla, el tratamiento de sus datos con las finalidades descritas en la Política de Privacidad, y además, Usted declara, bajo su propia responsabilidad, tener cumplidos los catorce años de edad, respondiendo de manera exclusiva y personal de la veracidad de dicha declaración y asumiendo, por ende, las posibles responsabilidades legales al respecto.";

const IthEstablishmentForm = (props: {
}) => {
  const navigate = useNavigate()

  const [creation, setCreation] = useState(false);
  
  // Data input
  const [establishmentName, setEstblishmentName] = useState<string>("");
  const [contactPersonName, setContactPersonName] = useState<string>("");
  const [contactPersonEmail, setContactPersonEmail] = useState<string>("");
  const [contactPersonPosition, setContactPersonPosition] = useState<string>("");
  const [autonomousRegion, setAutonomousRegion] = useState("");
  const [establishmentType, setEstablishmentType] = useState("");
  const [localization, setLocalization] = useState("");
  const [ithPartner, setIthPartner] = useState(false);
  const [ithLopd, setIthLopd] = useState(false);
  const [phoneMobile, setPhoneMobile] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [city, setCity] = useState<string>("");
   

  
  // Dialog info
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string>("")
  const [body, setBody] = useState<string>("")
  const handleClickOpen = (title, body) => { setOpen(true); setTitle(title); setBody(body); };
  const handleClose = () => { 
    setOpen(false);
    if(creation) {
      ContextManager.resetContext();
      navigate('/');
    } 
  };

  const [openLoadDialog, setOpenLoadDialog] = useState(false);

  const apiClient = ApiManager.getApiClientInstance();

  // General información
  const DIALOG_TITLE_BIENVENIDO : string = "¡ Bienvenido !";
  const DIALOG_BODY_BIENVENIDO : string = 
    `${ContextManager.applicationUser.firstName}, bienvenido a la Herramienta de diagnóstico de ITH. Para poder seguir avanzando necesitamos que nos proporciones
    algo de información de tu establecimiento. Una vez que hayas rellenado esta información podrás acceder al resto de secciones que te proporciona la herramienta.
    Gracias por tu colaboración.`;

  // Aviso de acceptación de Lopd
  const DIALOG_TITLE_PRIVICY_POLICY : string = "Aceptación de la política de privacidad";
  const DIALOG_BODY_PRIVICY_POLICY : string = 
    `Por favor, para poder registrar su establecimiento en la plataforma, es necesario que marque la casilla referente a la Política de Privacidad.`;

  // Show welcome information in a dialog
  useEffect(() => {
    if (ContextManager.establishment == null) {
      handleClickOpen(DIALOG_TITLE_BIENVENIDO, DIALOG_BODY_BIENVENIDO);
    }
    else {
      setEstblishmentName(ContextManager.establishment.name);
      setContactPersonName(ContextManager.establishment.contactName);
      setContactPersonEmail(ContextManager.establishment.contactEmail);
      setContactPersonPosition(ContextManager.establishment.contactPosition);
      setAutonomousRegion(ContextManager.establishment.idAutonomousRegion.toString());
      setEstablishmentType(ContextManager.establishment.idEstablishmentType.toString());
      setLocalization(ContextManager.establishment.idLocalization.toString());
      setIthPartner(ContextManager.establishment.ithPartner);
      setIthLopd(ContextManager.establishment.lopd);
      setPhoneMobile(ContextManager.establishment.phoneMobile);
      setPhone(ContextManager.establishment.phone);
      setCity(ContextManager.establishment.city);
    }
  }, []);

  const safeEstablishmentData = async (event) => {
    event.preventDefault()
    if(!ithLopd) {
      handleClickOpen(DIALOG_TITLE_PRIVICY_POLICY, DIALOG_BODY_PRIVICY_POLICY);
    }
    else if (establishmentName && 
      contactPersonName && 
      contactPersonEmail &&
      contactPersonPosition &&
      autonomousRegion &&
      establishmentType &&
      localization &&
      phoneMobile &&
      city) {
      
        setOpenLoadDialog(true);

      let iEstablishment: IEstablishment = {
        id: (ContextManager.establishment == null) ? ContextManager.getGUID(): ContextManager.establishment.id,
        name: establishmentName,
        idUser: ContextManager.applicationUser.id,
        idAutonomousRegion: Number(autonomousRegion),
        idEstablishmentType: Number(establishmentType),
        idLocalization: Number(localization),
        contactName: contactPersonName,
        contactEmail: contactPersonEmail,
        contactPosition: contactPersonPosition,
        ithPartner: ithPartner,
        lopd: ithLopd,
        phoneMobile: phoneMobile,
        phone: phone,
        city: city
      };

      let action: Promise<boolean> = null;
      let establishment: Establishment = new Establishment(iEstablishment);
      if(ContextManager.establishment == null) {
        action = apiClient.apiEstablishmentAdd(new Establishment(establishment));
      }
      else {
        action = apiClient.apiEstablishmentUpdate(new Establishment(establishment))
      }

      action.then((data: boolean) => {
        if(ContextManager.establishment == null) {
          setCreation(true);
          handleClickOpen(DIALOG_TITLE_CREATION_SUCCESS, DIALOG_BODY_CREATION_SUCCESS);
        }
        else {
          ContextManager.loadEstablishment();
          handleClickOpen(DIALOG_TITLE_UPDATE_SUCCESS, DIALOG_BODY_UPDATE_SUCCESS);
        }
      })
      .catch((e: ApiException) => {
        handleClickOpen(DIALOG_TITLE_ERROR, e.response);
      })
      .finally(() => {
        setOpenLoadDialog(false);
      });
    }
  };

  return (
    <Container maxWidth="xs" sx={{ textAlign: 'center' }}>
      <IthLoadDialog open={openLoadDialog} />
      <IthSimpleDialog
        open={open}
        title={title}
        body={body}
        onClose={handleClose}
      />

      <Box 
          component="form" 
          onSubmit={safeEstablishmentData} 
          noValidate 
          sx={{ marginTop: 0, display: "flex", flexDirection: "column", alignItems: "center", }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="establishmentName"
          label="Nombre del establecimiento"
          name="establishmentName"
          autoComplete="establishmentName"
          autoFocus
          value={establishmentName}
          onChange={(e) => setEstblishmentName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="contactPersonName"
          label="Nombre de la persona de contacto"
          name="contactPersonName"
          autoComplete="contactPersonName"
          value={contactPersonName}
          onChange={(e) => setContactPersonName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="contactPersonEmail"
          label="Email de la persona de contacto"
          name="contactPersonEmail"
          autoComplete="contactPersonEmail"
          value={contactPersonEmail}
          onChange={(e) => setContactPersonEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="contactPersonPosition"
          label="Cargo de la persona de contacto"
          name="contactPersonPosition"
          autoComplete="contactPersonPosition"
          value={contactPersonPosition}
          onChange={(e) => setContactPersonPosition(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="mobile"
          label="Teléfono móvil"
          name="mobile"
          autoComplete="mobile"
          value={phoneMobile}
          onChange={(e) => setPhoneMobile(e.target.value)}
        />
        <TextField
          margin="normal"
          fullWidth
          id="phone"
          label="Teléfono fijo"
          name="phone"
          autoComplete="phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="autonomousRegion"
          label="Comunidad Autonoma"
          value={autonomousRegion}
          onChange={(e) => setAutonomousRegion(e.target.value )}
        >
          {
            ContextManager.autonomousRegions.map((option) => {
              return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem> 
            })
          }
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          id="city"
          label="Ciudad"
          name="city"
          autoComplete="city"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="establishmentType"
          label="Tipo de establecimiento"
          value={establishmentType}
          onChange={(e) => setEstablishmentType(e.target.value)}
        >
          {
            ContextManager.establishmentTypes.map((option) => {
              return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem> 
            })
          }
        </TextField>
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="establishmentLocalization"
          label="Localizacion"
          value={localization}
          onChange={(e) => setLocalization(e.target.value)}
        >
          {
           ContextManager.localizations.map((option) => {
              return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem> 
            })
          }
        </TextField>

        <FormControlLabel control={<Switch
          checked={ithPartner}
          onChange={(e) => setIthPartner(!ithPartner)}
          inputProps={{ 'aria-label': 'controlled' }}
        />} 
        label={LABEL_TEXT_ITHPARTNER} />

        <br/>
        <FormControlLabel control={<Checkbox defaultChecked
          checked={ithLopd}
          onChange={(e) => setIthLopd(!ithLopd)}
          inputProps={{ 'aria-label': 'controlled' }}
        />} 
        label={LABEL_TEXT_LOPD} />
        
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Guardar
        </Button>
      </Box>
      
    </Container>

    
  );
}

export default IthEstablishmentForm;
