export class BaseClient {
    /**
     * authorization token value
     */
    public token: string
  
    constructor() {
        this.token = null
    }
  
    protected transformOptions(options: any) { 
        if (this.token) {
            options.headers["Authorization"] = "bearer " + this.token
        }
        return Promise.resolve(options); 
    } 
    
  }