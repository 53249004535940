import { Helmet } from 'react-helmet-async';

import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material';
import IthPageTitleWrapper from 'src/components/IthPageTitleWrapper';
import IthPageTitle from 'src/components/IthPageTitle';
import IthFooter from 'src/components/IthFooter';
import IthQuestionnaire from 'src/components/IthQuestionnaire';
import { ContextManager } from 'src/managers/ContextManager';
import { QuestionnaireTypes } from 'src/constants/QuestionnaireTypes';


function FoodWasteQuestionnairePage() {
  
  return (
    <>
      <Helmet>
        <title>Cuestionario desperdicio alimentario</title>
      </Helmet>
      <IthPageTitleWrapper>
        <IthPageTitle
          heading="Cuestionario de Desperdicio Alimentario"
          subHeading="En esta sección podrá rellenar un cuestionario, en base a la tipología de su establecimiento, que le permitirá obtener un resumen de forma gráfica, de su situación actual."
          buttonText='Leyenda del formulario'
          body={ContextManager.getQuestionnaireLegendsHtml(QuestionnaireTypes.Sustainability)}
          
        />
      </IthPageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Cuestionario de Desperdicio Alimentario" />
              <Divider />
              <CardContent>
                <IthQuestionnaire idQuestionnaire={QuestionnaireTypes.FoodWaste} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <IthFooter />
    </>
  );
}

export default FoodWasteQuestionnairePage;
