import PropTypes from 'prop-types';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
} from '@mui/material';

const BUTTON_TEXT_CLOSE = "Cerrar";

function IthSimpleDialog(props) {
  const { onClose, open, title, body } = props;
  const handleClose = () => { onClose(); };

  return (
    <Dialog maxWidth="lg" onClose={handleClose} open={open}>
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {BUTTON_TEXT_CLOSE}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

IthSimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default IthSimpleDialog;
