import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export class BenchmarkingReportPdfGenerator {

    /**
     * GENERATE PDF
     */
    public async DownloadBasicPdfView(
        establishmentName: string,
        bechmarkingType: string,
        resumeSustainabilityText: string,
        resumeOdsText: string,
        degreeAlignmentExplanantion: string
    ) {
        const doc = new jsPDF('p', 'mm', 'a4');
        
        let width = doc.internal.pageSize.getWidth();
        let height = doc.internal.pageSize.getHeight();
        let leftMargin = 20;
        let rightMargin = 20;
        let verticalOffset = 10;
        
        // 1. Portada
        await this.createFrontPage(establishmentName, bechmarkingType, doc, width, height, rightMargin, leftMargin, verticalOffset);
        // 2. Página de resumen
        doc.addPage();
        await this.createResumenPage(resumeSustainabilityText, resumeOdsText, doc, width, height, rightMargin, leftMargin, verticalOffset);
        //3. Página  grado de sostenibilidad
        doc.addPage();
        await this.createSustainabilityPage(doc, width, height, rightMargin, leftMargin, verticalOffset);
        // 4. Página de grado de alineación con odss
        doc.addPage();
        await this.createOdssPage(degreeAlignmentExplanantion, doc, width, height, rightMargin, leftMargin, verticalOffset);
        // 5. Generate pdf
        doc.save('InformeBenchmarking_' + Date.now() + '.pdf');
    }

    private async createPdfHeader(doc: jsPDF): Promise<void> {
        const logoIth = document.getElementById("logoIth");
        await html2canvas(logoIth, { scale: 1 }).then(function (canvas) { 
            doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 5, 5, 25, 17);
        });

        const logoSecretaria = document.getElementById("logoSecretaria");
        await html2canvas(logoSecretaria, { scale: 1 }).then(function (canvas) { 
            doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 110, 5, 97, 17);
        });
    }

    private async createFooter(
        doc: jsPDF,
        width: number,
        rightMargin: number,
        leftMargin: number, 
        verticalOffset: number
    ): Promise<void> {
        doc.setFontSize(10);
        var lines = doc.splitTextToSize("Coralía Pino", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*27);
        lines = doc.splitTextToSize("Responsable del Área de Sostenibilidad y Eficiencia Energética", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*27.5);
        lines = doc.splitTextToSize("® 2021 ITH, Instituto Tecnológico Hotelero", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*28);
        lines = doc.splitTextToSize("C/ Orense, 32 28020 Madrid - t +34 914 17 12 46 - cpino@ithotelero.com", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*28.5);
    }

    private async createFrontPage(
        establishmentName: string,
        bechmarkingType: string,
        doc: jsPDF, 
        width: number, 
        height: number, 
        rightMargin: number,
        leftMargin: number, 
        verticalOffset: number): Promise<void> 
    {
        // 1. Header
        const logoIth = document.getElementById("logoIth");
        await html2canvas(logoIth, { scale: 1 }).then(function (canvas) { 
            doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', leftMargin*1.5, 15, 40, 27);
        });

        // 2. Título
        doc.setFontSize(20);
        var lines = doc.splitTextToSize("TEST DE AUTOEVALUACIÓN EN SOSTENIBILIDAD HOTELERA", (width-rightMargin-leftMargin-20));
        doc.text(lines, leftMargin*1.5, verticalOffset*10);

        // 3. Subtítulo
        doc.setFontSize(16);
        lines = doc.splitTextToSize("INFORME DE BENCHMARKING", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*14);

        // 4. Subtítulo
        doc.setFontSize(16);
        lines = doc.splitTextToSize(bechmarkingType, (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*16);

        // 4. Hotel
        doc.setFontSize(14);
        lines = doc.splitTextToSize("HOTEL: " + establishmentName.toLocaleUpperCase(), (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*18);

        // 5. Fecha
        const now = new Date();
        doc.setFontSize(14);
        lines = doc.splitTextToSize("FECHA: " + now.toLocaleDateString(), (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*20);

        // 4. Pie
        doc.setFontSize(10);
        lines = doc.splitTextToSize("MODELO ITH SOSTENIBILIDAD TURÍSTICA Y PLANES DE MEJORA", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*27);
        doc.setFontSize(10);
        lines = doc.splitTextToSize("Proyecto suvencionado por:", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*28);

        const logoSecretaria = document.getElementById("logoSecretaria");
        await html2canvas(logoSecretaria, { scale: 1 }).then(function (canvas) { 
            doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', leftMargin*1.5, verticalOffset*29, 97, 17);
        });
               
    }

    private async createResumenPage(
        resumeSustainabilityText: string,
        resumeOdsText: string,
        doc: jsPDF, 
        width: number, 
        height: number, 
        rightMargin: number,
        leftMargin: number, 
        verticalOffset: number): Promise<void> 
    {
        // 1. Header
        await this.createPdfHeader(doc);

        // 2. Título
        doc.setFont(undefined, 'bold');
        doc.setFontSize(16);
        var lines = doc.splitTextToSize("1. Resumen de diagnóstico", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*5);
        
        // 3. Texto resumen
        const reportResume: string = "El presente informe de resultados realiza un análisis comparativo de los resultados según la muestra establecida por la propia BBDD del aplicativo. Según la selección aplicada, el siguiente informe se ha elaborado a partir de un total de 6 correspondientes a los hoteles registrados que coinciden con los filtros indicados.";
        doc.setFont(undefined, 'normal');
        doc.setFontSize(12);
        lines = doc.splitTextToSize(reportResume, (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*6);

        // 4. Texto sostenibilidad
        doc.setFont(undefined, 'normal');
        doc.setFontSize(12);
        lines = doc.splitTextToSize(resumeSustainabilityText, (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*9);

        // 5. Texto ODS
        doc.setFont(undefined, 'normal');
        doc.setFontSize(12);
        lines = doc.splitTextToSize(resumeOdsText, (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*11);

         // 6. Pie
         await this.createFooter(doc, width, rightMargin, leftMargin, verticalOffset)
    }


    private async createSustainabilityPage(
        doc: jsPDF, 
        width: number, 
        height: number, 
        rightMargin: number,
        leftMargin: number, 
        verticalOffset: number): Promise<void> 
    {
        // 1. Header
        await this.createPdfHeader(doc);

        // 2. Título
        doc.setFont(undefined, 'bold');
        doc.setFontSize(16);
        var lines = doc.splitTextToSize("2. Grado de sostenibilidad", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*5);

        // 3. Explicación
        const resumeExplanation: string = 
        `Para cada una de las secciones en las que se divide el cuestionario, se presentan dos barras verticales, una indicando la media obtenida por los hoteles de la muestra con los que se compara, y otra el valor actual de su establecimiento.`;
        doc.setFont(undefined, 'normal');
        doc.setFontSize(12);
        lines = doc.splitTextToSize(resumeExplanation, (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*6);

        // 4. Graficos de resumen
        const sustainability = document.getElementById("sustainability");
        await html2canvas(sustainability, { scale: 1 }).then(function (canvas) { 
            doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 15, 90, canvas.width * 0.15, canvas.height * 0.15);
        });

         // 6. Pie
         await this.createFooter(doc, width, rightMargin, leftMargin, verticalOffset)
    }


    private async createOdssPage(
        degreeAlignmentExplanantion: string,
        doc: jsPDF, 
        width: number, 
        height: number, 
        rightMargin: number,
        leftMargin: number, 
        verticalOffset: number): Promise<void> 
    {
        // 1. Header
        await this.createPdfHeader(doc);

        // 2. Título
        doc.setFont(undefined, 'bold');
        doc.setFontSize(16);
        var lines = doc.splitTextToSize("3. Grado de alineación con los ODSs", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*5);

        // 3. Explicación
        doc.setFont(undefined, 'normal');
        doc.setFontSize(12);
        lines = doc.splitTextToSize(degreeAlignmentExplanantion, (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*6);

        // 4. Graficos de resumen
        const sustainability = document.getElementById("odss");
        await html2canvas(sustainability, { scale: 1 }).then(function (canvas) { 
            doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', -75, 90, canvas.width * 0.30, canvas.height * 0.30);
        });

         // 6. Pie
         await this.createFooter(doc, width, rightMargin, leftMargin, verticalOffset)
    }
    
}
