import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import EstablishmentPage from 'src/content/establishment';
import SustainabilityQuestionnairePage from './content/sustainabilityQuestionnaire';
import FoodWasteQuestionnairePage from './content/foodWasteQuestionnaire';
import SustainabilityDiagnosticPage from './content/sustainabilityDiagnostic';
import FoodwasteDiagnosticPage from './content/foodwasteDiagnostic';
import TipsPostSustainabilityMemoryPage from './content/tipsPostSustainabilityMemory';
import SustainabilityMemoryPage from './content/sustainabilityMemory';
import SustainabilityBenchmarkingPage from './content/sustainabilityBenchmarking';
import FoodwasteBenchmarkingPage from './content/foodwasteBenchmarking';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Overview
const Overview = Loader(lazy(() => import('src/content/overview')));

// Register
const Register = Loader(lazy(() => import('src/content/register')));

// Odss2030Agenda
const Odss2030AgendaPage = Loader(lazy(() => import('src/content/odss2030agenda')));


const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'register',
        element: <Register />
      }
    ]
  },
  {
    path: 'establishment',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="establishmentdata" replace /> 
      },
      {
        path: 'establishmentdata',
        element: <EstablishmentPage />
      },
    ]
  },
  {
    path: 'odss',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="odss2030agenda" replace />
      },
      {
        path: 'odss2030agenda',
        element: <Odss2030AgendaPage /> 
      }
    ]
  },
  {
    path: 'sustainability',
    element: <SidebarLayout />,
    children: [
      {
        path: 'sustainabilityQuestionnaire',
        element: <SustainabilityQuestionnairePage /> 
      },
      {
        path: 'sustainabilityDiagnostic',
        element: <SustainabilityDiagnosticPage /> 
      },
      {
        path: 'sustainabilityBenchmarking',
        element: <SustainabilityBenchmarkingPage />
      }
    ]
  },
  {
    path: 'foodwaste',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="foodwasteQuestionnaire" replace />
      },
      {
        path: 'foodwasteQuestionnaire',
        element: <FoodWasteQuestionnairePage /> 
      },
      {
        path: 'foodwasteDiagnostic',
        element: <FoodwasteDiagnosticPage /> 
      },
      {
        path: 'foodwasteBenchmarking',
        element: <FoodwasteBenchmarkingPage />
      }
      
    ]
  },
  {
    path: 'sustainabilityMemory',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="tipsPost" replace />
      },
      {
        path: 'memory',
        element: <SustainabilityMemoryPage /> 
      },
      {
        path: 'tipsPost',
        element: <TipsPostSustainabilityMemoryPage /> 
      },
      
    ]
    
  }
  
];

export default routes;
