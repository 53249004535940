import { ApiManager } from "./ApiManager";
import { ApplicationUser, AutonomousRegion, Establishment, EstablishmentType, Localization, Question, QuestionnaireLegend, QuestionnaireSection, SustainabilityMemory } from "src/api/ApiClient";

export class ContextManager {
    
    /*
     * Public properties
     */
    public static applicationUser: ApplicationUser = null;

    public static establishment: Establishment = null;

    public static autonomousRegions: AutonomousRegion[] = null;

    public static establishmentTypes: EstablishmentType[] = null;

    public static localizations: Localization[] = null;

    public static questionnaireLegends: QuestionnaireLegend[] = null;

    public static questionnairesSections: QuestionnaireSection[] = null;

    public static questions: Question[] = null;

    /*
     * Prepare context
     */
    public static async prepareContext(username: string, token: any) {
        ApiManager.getApiClientInstance().token = token;
        
        await ContextManager.setUser(username);
        await ContextManager.getMasterData();
        await ContextManager.getEstablishment();
    }

    private static async setUser(username: string): Promise<void> {
        let apiClient = ApiManager.getApiClientInstance();
        await apiClient.apiAuthenticationGetUserByEmail(username)
            .then((data: ApplicationUser) => { ContextManager.applicationUser = data; });
    }

    private static async getMasterData(): Promise<void> {
        let apiClient = ApiManager.getApiClientInstance();
        await Promise.all([
            apiClient.apiAutonomousRegionGetList().then( (data: AutonomousRegion[]) => { ContextManager.autonomousRegions = data; } ),
            apiClient.apiEstablishmentTypeGetList().then( (data: EstablishmentType[]) => { ContextManager.establishmentTypes = data; } ),
            apiClient.apiLocalizationGetList().then( (data: Localization[]) => { ContextManager.localizations = data; } ),
            apiClient.apiQuestionnaireLegendGetList().then( (data: QuestionnaireLegend[]) => { ContextManager.questionnaireLegends = data; } ),
            apiClient.apiQuestionnaireSectionGetList().then( (data: QuestionnaireSection[]) => { ContextManager.questionnairesSections = data; } ),
            apiClient.apiQuestionGetList().then( (data: Question[]) => { ContextManager.questions = data; } ),
        ]);
    }

    private static async getEstablishment(): Promise<void> {
        let apiClient = ApiManager.getApiClientInstance();
        await apiClient.apiEstablishmentGetEstablishmentByUserId(ContextManager.applicationUser.id)
            .then((data: Establishment) => { 
                ContextManager.establishment = data; 
            } );
    }

    /*
     * Get establishment public
     */
    public static async loadEstablishment(): Promise<void> {
        await ContextManager.getEstablishment();
    }

    /*
     * Reset Context for Logout
     */
    public static resetContext(): void {
        ContextManager.applicationUser = null;
        ContextManager.establishment = null;
        ContextManager.autonomousRegions = null;
        ContextManager.establishmentTypes = null;
        ContextManager.localizations = null;
    }

    /*
     * Tools
     */
    public static getGUID(): string {
        let d = new Date().getTime();
        const guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return guid;
    }


    public static getQuestionnaireLegendsHtml(idQuestionnaire: string): string {
        let htmlString: string = "";
        ContextManager.questionnaireLegends
            .filter(x => x.idQuestionnaire == idQuestionnaire)
            .map((item, index) => {
                htmlString += `<div><img src="/static/images/${item.imageName}" width="${item.value}" height="${item.value}" /><p>${item.name}</p></div>`;
            });
          
          return htmlString;
      };

}