import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  styled,
} from '@mui/material';
import Typography from '@mui/material/Typography';

let CONST_TEXT_RESUME: string = "Una vez elaborada la memoria de sostenibilidad, es importante implementar estrategias efectivas para su difusión y aprovecharla como una herramienta de compromiso con las personas u organizaciones que se relacionan con las actividades y decisiones del establecimiento hotelero y el público en general. La consistencia en la comunicación y el compromiso a largo plazo son clave para exponer los esfuerzos y logros del hotel o la cadena hotelera en términos de sostenibilidad, así como construir y mantener una imagen sólida en sostenibilidad. A continuación, se exponen algunos enfoques prácticos para la comunicación efectiva de una memoria de sostenibilidad:";  
let CONST_POINT1_TITLE: string = "Publicación en la página web del hotel o de la cadena hotelera.";
let CONST_POINT1_INFO: string = "Incorporar la memoria de sostenibilidad en una sección destacada de la página web del establecimiento hotelero o de la cadena hotelera. Es importante cerciorarse de que la ubicación de la memoria sea fácilmente accesible para los visitantes de la página web.";
let CONST_POINT2_TITLE: string = "Difusión en Redes Sociales. ";
let CONST_POINT2_INFO: string = "Difusión de la memoria de sostenibilidad a través de las redes sociales del hotel o de la cadena hotelera. Compartir aspectos clave, logros y datos interesantes de la memoria. Utilizar gráficos y contenido visual para captar la atención de los usuarios.";
let CONST_POINT3_TITLE: string = "Nota de Prensa / presencia en medios de comunicación de interés. ";
let CONST_POINT3_INFO: string = "Enviar un comunicado o nota de prensa a los medios locales y especializados destacando los logros y compromisos en sostenibilidad. Realizar acuerdos o colaboraciones con los medios de comunicación para motivar la difusión de la memoria de sostenibilidad. ";
let CONST_POINT4_TITLE: string = "Boletín Informativo (newsletter)";
let CONST_POINT4_INFO: string = "Incluye resúmenes y enlaces a la memoria en los boletines informativos/newsletter del hotel o de los hoteles de la cadena hotelera. Informa a los suscriptores sobre los esfuerzos sostenibles y cómo pueden participar.";
let CONST_POINT5_TITLE: string = "Presentación o evento de lanzamiento.";
let CONST_POINT5_INFO: string = "Organizar una presentación o evento de lanzamiento para presentar la memoria de sostenibilidad. Invitar a las personas u organizaciones que se relacionan con las actividades y decisiones del establecimiento hotelero, clientes, medios y líderes de la comunidad.";
let CONST_POINT6_TITLE: string = "Colaboración con Influencers, Bloggers o creadores de contenido.";
let CONST_POINT6_INFO: string = "Colaborar con influencers o líderes de opinión en el ámbito de la sostenibilidad para amplificar el mensaje a través de sus canales digitales.";
let CONST_POINT7_TITLE: string = "Comunicaciones internas.";
let CONST_POINT7_INFO: string = "Es muy importante involucrar a los empleados del hotel o la cadena hotelera en el proceso de elaboración de la memoria de sostenibilidad. El hecho de estar informados sobre la memoria de sostenibilidad fomenta la participación activa en las iniciativas sostenibles del hotel y genera un compromiso transversal para con la sostenibilidad.";
let CONST_POINT8_TITLE: string = "Comunicación para los huéspedes.";
let CONST_POINT8_INFO: string = "Informar a los huéspedes del establecimiento hotelero o de la cadena hotelera de la existencia de la memoria de sostenibilidad. Facilitar el acceso al documento.";
let CONST_POINT9_TITLE: string = "Participación en Eventos Sectoriales.";
let CONST_POINT9_INFO: string = "Presentar la memoria de sostenibilidad en eventos, ferias o conferencias del sector hotelero para compartir las mejores prácticas y aprender de otras experiencias.";

let CONST_POINT10_TITLE: string = "Por último, a nivel comunicativo es importante adaptar el tono y el contenido según el canal de distribución.";
let CONST_POINT10_INFO: string = "Adaptar el tono y el contenido según el canal de distribución es esencial para maximizar la efectividad de la comunicación. Cada canal tiene su propio formato, audiencia y estilo, por lo que ajustar la comunicación a estas particularidades puede mejorar el alcance de las acciones de comunicación. A continuación, algunas sugerencias para adaptar el tono y el contenido según los diferentes canales de distribución:";
let CONST_POINT11_TITLE: string = "Página Web del hotel o cadena hotelera.";
let CONST_POINT11_INFO: string = "Tono: Profesional y reflexivo.<br/>Contenido: Publicar la memoria de sostenibilidad de forma detallada. Proporcionar enlaces a informes completos y datos específicos. Destacar logros clave y metas futuras.";
let CONST_POINT12_TITLE: string = "Redes Sociales:";
let CONST_POINT12_INFO: string = "Tono: Más informal, positivo y participativo.<br/>Contenido: Utilizar gráficos, imágenes y videos atractivos. Compartir historias breves sobre prácticas sostenibles. Animar a la participación con preguntas y encuestas.";
let CONST_POINT13_TITLE: string = "Correos electrónicos y newsletter.";
let CONST_POINT13_INFO: string = "Tono: Personalizado y agradecido.<br/>Contenido: Resaltar los logros más importantes. Incluir mensajes para generar participación y respuestas por parte de los usuarios. Proporcionar enlaces directos a la memoria de sostenibilidad en la página web.";
let CONST_POINT14_TITLE: string = "Presentación o eventos de lanzamiento.";
let CONST_POINT14_INFO: string = "Tono: formal y comprometido.<br/>Contenido: Presentar los aspectos clave de la memoria de sostenibilidad. Proporcionar copias impresas o digitales del informe. Facilitar entrevistas con los responsables de la elaboración de la memoria de sostenibilidad o con el equipo directivo.";
let CONST_POINT15_TITLE: string = "Influencers, bloggers y creadores de contenido:";
let CONST_POINT15_INFO: string = "Tono: Colaborativo y respetuoso.<br/>Contenido: Destacar las colaboraciones y asociaciones sostenibles del hotel. Proporcionar contenido exclusivo para compartir en sus plataformas. Fomentar la creación de contenido conjunto.";
let CONST_POINT16_TITLE: string = "Comunicación Interna con los empleados.";
let CONST_POINT16_INFO: string = "Tono: Inspirador y motivador.<br/>Contenido: Destacar el papel crucial de los empleados en los logros sostenibles. Proporcionar recursos internos. Animar a compartir la información con colegas del sector.";
let CONST_POINT17_TITLE: string = "Publicaciones especializadas o revistas del Sector Hotelero.";
let CONST_POINT17_INFO: string = "Tono: Adaptado al formato del medio.<br/>Contenido: Proporcionar datos específicos sobre prácticas sostenibles en establecimiento o cadena hotelera. Destaca la posición del hotel o la cadena hotelera como líder en sostenibilidad. Incluir entrevistas con el equipo directivo. Resumir los puntos clave de manera clara y concisa. Adaptar el mensaje según la audiencia específica del medio.";

const TypographyExplanation = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(16)};` );
const TypographyTitle = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(16)};` );

const IthTipsPostMemory = (props: {
}) => {
  return (
    <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Publicación y difusión: consejos prácticos para el uso y correcta difusión del documento. " />
              <Divider />
              <CardContent>
                <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_TEXT_RESUME }} /></TypographyExplanation>
                <br />
                <ul>
                  <TypographyTitle variant="h3"><li>{CONST_POINT1_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT1_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT2_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT2_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT3_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT3_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT4_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT4_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT5_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT5_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT6_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT6_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT7_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT7_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT8_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT8_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT9_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT9_INFO }} /></TypographyExplanation>
                </ul>
                <br />
                <TypographyTitle variant="h3">{CONST_POINT10_TITLE}</TypographyTitle>
                <br/>
                <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT10_INFO }} /></TypographyExplanation>
                <ul>
                  <TypographyTitle variant="h3"><li>{CONST_POINT10_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT10_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT11_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT11_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT12_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT12_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT13_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT13_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT14_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT14_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT15_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT15_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT16_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT16_INFO }} /></TypographyExplanation>
                  <br/>
                  <TypographyTitle variant="h3"><li>{CONST_POINT17_TITLE}</li></TypographyTitle>
                  <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CONST_POINT17_INFO }} /></TypographyExplanation>
                </ul>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
  );
}

export default IthTipsPostMemory;
