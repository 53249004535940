import { Helmet } from 'react-helmet-async';
import IthPageTitleWrapper from 'src/components/IthPageTitleWrapper';
import IthPageTitle from 'src/components/IthPageTitle';
import IthFooter from 'src/components/IthFooter';
import IthTipsPostMemory from 'src/components/IthTipsPostMemory';

function TipsPostSustainabilityMemoryPage() {
  return (
    <>
      <Helmet>
        <title>Memoria de sostenibilidad</title>
      </Helmet>
      <IthPageTitleWrapper>
        <IthPageTitle
          heading="Consejos para la publicación y difusión de la memoria de sostenibilidad"
          subHeading="En esta sección podrá revisar algunos consejos prácticos para el uso y correcta difusión de la memoria de sostenibilidad, una vez que haya sido creada."
          buttonText=''
          body=''
        />
      </IthPageTitleWrapper>
      <IthTipsPostMemory />
      <IthFooter />
    </>
  );
}

export default TipsPostSustainabilityMemoryPage;
