import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Question } from "src/api/ApiClient";

export class SustainabilityReportPdfGenerator {

    /**
     * GENERATE PDF
     */
    public async DownloadBasicPdfView(
        establishmentName: string,
        reportType: string,
        questions: Question[],
        resumeSustainabilityText: string,
        resumeOdsText: string,
    ) {
        const doc = new jsPDF('p', 'mm', 'a4');
        doc.setLanguage("es-ES");
        let width = doc.internal.pageSize.getWidth();
        let height = doc.internal.pageSize.getHeight();
        let leftMargin = 20;
        let rightMargin = 20;
        let verticalOffset = 10;
        
        // 1. Portada
        await this.createFrontPage(establishmentName, reportType, doc, width, height, rightMargin, leftMargin, verticalOffset);
        // 2. Página de resumen
        doc.addPage();
        await this.createResumenPage(resumeSustainabilityText, resumeOdsText,doc, width, height, rightMargin, leftMargin, verticalOffset);
        // 3. Página  grado de sostenibilidad
        doc.addPage();
        await this.createSustainabilityPage(doc, width, height, rightMargin, leftMargin, verticalOffset);
        // 4. Página de grado de alineación con odss
        doc.addPage();
        await this.createOdssPage(doc, width, height, rightMargin, leftMargin, verticalOffset);
        // 5. Página puntos débiles y recomendaciones
        doc.addPage();
        await this.createWeakPointsPage(doc, width, height, rightMargin, leftMargin, verticalOffset, questions);
        // 6. Generate pdf
        doc.save('InformeAutodiagnostico_' + Date.now() + '.pdf');
    }

    private async createPdfHeader(doc: jsPDF): Promise<void> {
        const logoIth = document.getElementById("logoIth");
        await html2canvas(logoIth, { scale: 1 }).then(function (canvas) { 
            doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 5, 5, 25, 17);
        });

        const logoSecretaria = document.getElementById("logoSecretaria");
        await html2canvas(logoSecretaria, { scale: 1 }).then(function (canvas) { 
            doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 110, 5, 97, 17);
        });
    }

    private async createFooter(
        doc: jsPDF,
        width: number,
        rightMargin: number,
        leftMargin: number, 
        verticalOffset: number
    ): Promise<void> {
        doc.setFontSize(10);
        var lines = doc.splitTextToSize("Coralía Pino", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*27);
        lines = doc.splitTextToSize("Responsable del Área de Sostenibilidad y Eficiencia Energética", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*27.5);
        lines = doc.splitTextToSize("® 2021 ITH, Instituto Tecnológico Hotelero", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*28);
        lines = doc.splitTextToSize("C/ Orense, 32 28020 Madrid - t +34 914 17 12 46 - cpino@ithotelero.com", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*28.5);
    }

    private async createFrontPage(
        establishmentName: string,
        reportType: string,
        doc: jsPDF, 
        width: number, 
        height: number, 
        rightMargin: number,
        leftMargin: number, 
        verticalOffset: number): Promise<void> 
    {
        // 1. Header
        const logoIth = document.getElementById("logoIth");
        await html2canvas(logoIth, { scale: 1 }).then(function (canvas) { 
            doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', leftMargin*1.5, 15, 40, 27);
        });

        // 2. Título
        doc.setFontSize(20);
        var lines = doc.splitTextToSize(reportType, (width-rightMargin-leftMargin-20));
        doc.text(lines, leftMargin*1.5, verticalOffset*10);

        // 3. Subtítulo
        doc.setFontSize(16);
        lines = doc.splitTextToSize("INFORME DE DIAGNÓSTICO", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*14);

        // 4. Hotel
        doc.setFontSize(14);
        lines = doc.splitTextToSize("HOTEL: " + establishmentName.toLocaleUpperCase(), (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*16);

        // 5. Fecha
        const now = new Date();
        doc.setFontSize(14);
        lines = doc.splitTextToSize("FECHA: " + now.toLocaleDateString(), (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*18);

        // 4. Pie
        doc.setFontSize(10);
        lines = doc.splitTextToSize("MODELO ITH SOSTENIBILIDAD TURÍSTICA Y PLANES DE MEJORA", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*25);
        doc.setFontSize(10);
        lines = doc.splitTextToSize("Proyecto suvencionado por:", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin*1.5, verticalOffset*26);

        const logoSecretaria = document.getElementById("logoSecretaria");
        await html2canvas(logoSecretaria, { scale: 1 }).then(function (canvas) { 
            doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', leftMargin*1.5, verticalOffset*27, 97, 17);
        });
               
    }

    private async createResumenPage(
        resumeSustainabilityText: string,
        resumeOdsText: string,
        doc: jsPDF, 
        width: number, 
        height: number, 
        rightMargin: number,
        leftMargin: number, 
        verticalOffset: number): Promise<void> 
    {
        // 1. Header
        await this.createPdfHeader(doc);

        // 2. Título
        doc.setFont(undefined, 'bold');
        doc.setFontSize(16);
        var lines = doc.splitTextToSize("1. Resumen de diagnóstico", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*5);

        // 3. Texto resumen
        const reportResume: string = "El presente informe de resultados realiza un análisis comparativo de los resultados según la muestra establecida por la propia BBDD del aplicativo. Según la selección aplicada, el siguiente informe se ha elaborado a partir de un total de 6 correspondientes a los hoteles registrados que coinciden con los filtros indicados.";
        doc.setFont(undefined, 'normal');
        doc.setFontSize(12);
        lines = doc.splitTextToSize(reportResume, (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*6);

        // 4. Texto sostenibilidad
        doc.setFont(undefined, 'normal');
        doc.setFontSize(12);
        lines = doc.splitTextToSize(resumeSustainabilityText, (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*9);

        // 5. Texto ODS
        doc.setFont(undefined, 'normal');
        doc.setFontSize(12);
        lines = doc.splitTextToSize(resumeOdsText, (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*11);

        // 6. Pie
        await this.createFooter(doc, width, rightMargin, leftMargin, verticalOffset)
    }

    private async createSustainabilityPage(
        doc: jsPDF, 
        width: number, 
        height: number, 
        rightMargin: number,
        leftMargin: number, 
        verticalOffset: number): Promise<void> 
    {
        // 1. Header
        await this.createPdfHeader(doc);

        // 2. Título
        doc.setFont(undefined, 'bold');
        doc.setFontSize(16);
        var lines = doc.splitTextToSize("2. Grado de sostenibilidad", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*5);

        // 3. Explicación
        const resumeExplanation: string = 
            `Para cada una de las secciones en las que se divide el cuestionario, se presentan dos barras verticales, una en azul indicando la puntación total que puede llegar a alcanzar un establecimiento en base a la puntuación asignada a cada respuesta, y en naranja el valor actual de dicho establecimiento en base a las respuestas indicadas al rellenar el cuestionario.`;
        doc.setFont(undefined, 'normal');
        doc.setFontSize(12);
        lines = doc.splitTextToSize(resumeExplanation, (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*6);

        // 4. Graficos de resumen
        const sustainability = document.getElementById("sustainability");
        await html2canvas(sustainability, { scale: 1 }).then(function (canvas) { 
            doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 15, 90, canvas.width * 0.15, canvas.height * 0.15);
        });

        // 5. Pie
        await this.createFooter(doc, width, rightMargin, leftMargin, verticalOffset)
    }

    private async createOdssPage(
        doc: jsPDF, 
        width: number, 
        height: number, 
        rightMargin: number,
        leftMargin: number, 
        verticalOffset: number): Promise<void> 
    {
        // 1. Header
        await this.createPdfHeader(doc);

        // 2. Título
        doc.setFont(undefined, 'bold');
        doc.setFontSize(16);
        var lines = doc.splitTextToSize("3. Grado de alineación con los ODSs", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*5);

        // 3. Explicación
        const resumeExplanation: string = 
            `El grado de alineación con los ODSs se presenta en forma de radar, de tal manera que se pueda ver, en base a los porcentajes cubiertos en cada uno de ellos, el nivel de alineación en cada caso, así como el área aproximada de cumplimiento.`;
        doc.setFont(undefined, 'normal');
        doc.setFontSize(12);
        lines = doc.splitTextToSize(resumeExplanation, (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*6);

        // 4. Graficos de resumen
        const sustainability = document.getElementById("odss");
        await html2canvas(sustainability, { scale: 1 }).then(function (canvas) { 
            doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', -75, 90, canvas.width * 0.30, canvas.height * 0.30);
        });

        // 5. Pie
        await this.createFooter(doc, width, rightMargin, leftMargin, verticalOffset)
    }

    private async createWeakPointsPage(
        doc: jsPDF, 
        width: number, 
        height: number, 
        rightMargin: number,
        leftMargin: number, 
        verticalOffset: number,
        questions: Question[]): Promise<void> 
    {
        // 1. Header
        await this.createPdfHeader(doc);
        await this.createFooter(doc, width, rightMargin, leftMargin, verticalOffset);

        // 2. Título
        doc.setFont(undefined, 'bold');
        doc.setFontSize(16);
        var lines = doc.splitTextToSize("4. Puntos débiles y recomendaciones", (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*5);

        // 3. Explicación
        const resumeExplanation: string = 
            `A continuación se listan aquellas preguntas en las que existe un mayor orden de mejora en base a sus respuestas en el cuestionario de autoevaluación.`;
        doc.setFont(undefined, 'normal');
        doc.setFontSize(12);
        lines = doc.splitTextToSize(resumeExplanation, (width-rightMargin-leftMargin));
        doc.text(lines, leftMargin, verticalOffset*6);

        // 4. puntos débiles
        const charactersByLine: number = 70;
        let linesPage: number = 60;
        let lineHeight: number = 5;
        let currentpage: number = 1;
        let totalOffset: number = (currentpage == 1) ? verticalOffset*8 : verticalOffset*6;
        let maxTotalOffset: number = (currentpage == 1) ? (linesPage-5)*lineHeight : (linesPage)*lineHeight;
        

        const chunk = 2;
        for (let i = 0; i < questions.length; i++) {

            let questionLinesNumber: number = (questions[i].body.length) / charactersByLine;
            let reccomendationLinesNumber: number = (questions[i].questionQuestionInfo.body.length) / charactersByLine;    
            
            let futureOffset: number = totalOffset + ((questionLinesNumber+1)*lineHeight) + ((reccomendationLinesNumber+1)*lineHeight);
            if(futureOffset >= maxTotalOffset) {
                doc.addPage();
                await this.createPdfHeader(doc);
                currentpage++;
                totalOffset= (currentpage == 1) ? verticalOffset*8 : verticalOffset*6;
                await this.createFooter(doc, width, rightMargin, leftMargin, verticalOffset);
            }

            doc.setFont(undefined, 'bold');
            doc.setFontSize(12);
            lines = doc.splitTextToSize(questions[i].body.replace(/(<([^>]+)>)/ig, ''), (width-rightMargin-leftMargin));
            doc.text(lines, leftMargin, totalOffset);
            totalOffset += (questionLinesNumber+1)*lineHeight;

            doc.setFont(undefined, 'normal');
            doc.setFontSize(12);
            var questionQuestionInfo = questions[i].questionQuestionInfo.body.replace(/(<([^>]+)>)/ig, '');
            lines = doc.splitTextToSize(questionQuestionInfo, (width-rightMargin-leftMargin));
            doc.text(lines, leftMargin, totalOffset);
            totalOffset += (reccomendationLinesNumber+1)*lineHeight;
        }
    }

}