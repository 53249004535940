import { Helmet } from 'react-helmet-async';

import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  IconButton,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IthPageTitleWrapper from 'src/components/IthPageTitleWrapper';
import IthPageTitle from 'src/components/IthPageTitle';
import IthFooter from 'src/components/IthFooter';
import IthQuestionnaire from 'src/components/IthQuestionnaire';
import { ContextManager } from 'src/managers/ContextManager';
import { QuestionnaireTypes } from 'src/constants/QuestionnaireTypes';
import IthSimpleDialog from 'src/components/IthSimpleDialog';
import { useState } from 'react';

let CONST_RULES: string = `
      <span>
      <p>
      A continuación, se presenta el test de autoevaluación en sostenibilidad hotelera, diseñado para verificar y calificar el grado de sostenibilidad en establecimientos hoteleros. Este test de autoevaluación consiste en una serie de medidas, actuaciones y requisitos que cubren de forma integral todos los campos de sostenibilidad ambiental, económica y social que se aplican a los procesos y servicios que conforman un hotel.
      </p>
      <p>
      La integración de estas medidas y protocolos en el sistema de gestión del hotel, involucrando tanto al personal como a los clientes y otras partes interesadas, asegura la aplicación de las mejores prácticas y la optimización de los recursos. De esta manera, se busca minimizar el impacto ambiental, económico y social del hotel, al mismo tiempo que se maximizan los ahorros económicos derivados.
      </p>
      <p>
      El test de autoevaluación en sostenibilidad para hoteles está dividido en tres secciones: sostenibilidad ambiental, sostenibilidad económica y sostenibilidad social.
      </p>
      <p>
      La estructura del cuestionario incluye la exposición de cada pregunta seguida de las opciones de respuesta. Además, se proporciona información detallada sobre la contribución de cada pregunta a los Objetivos de Desarrollo Sostenible, alineando así los criterios de sostenibilidad establecidos en el cuestionario con los ODS y la Agenda 2023. Cada pregunta también cuenta con un apartado de recomendaciones y buenas prácticas, junto con ejemplos sencillos de indicadores de diagnóstico que el establecimiento puede utilizar para dar seguimiento a cada medida de sostenibilidad.
      </p>
      <p>
      Los resultados introducidos en el cuestionario de sostenibilidad se guardarán automáticamente una vez introducidos y quedarán registrados en la plataforma. Estos datos son usados para generar los informes de diagnóstico, estos pueden consultarse en su apartado correspondiente de la plataforma y también pueden descargarse. 
      </p>
      <p>
      A medida que implementen mejoras en su establecimiento hotelero, pueden modificar las respuestas del cuestionario, lo que actualizará automáticamente los resultados en los informes de diagnóstico.
      </p>
      </span>
    `;  



function SustainabilityQuestionnairePage() {
  const [open, setOpen] = useState(false);
  const handleClose = () => { setOpen(false); };
  return (
    <>
      <Helmet>
        <title>Establecimiento - Datos</title>
      </Helmet>
      <IthPageTitleWrapper>
        <IthPageTitle
          heading="Cuestionario de Sostenibilidad de ITH"
          subHeading="En esta sección podrá rellenar un cuestionario, en base a la tipología de su establecimiento, que le permitirá obtener información sobre el grado de alineación de su establecimiento hotelero en base a los criterios de sostenibilidad establecidos."
          buttonText='Consejos para la cumplimentación'
          body={CONST_RULES}
        />
      </IthPageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>

          <IthSimpleDialog
            open={open}
            title="Leyenda del cuestionario"
            body={ContextManager.getQuestionnaireLegendsHtml(QuestionnaireTypes.Sustainability)}
            onClose={handleClose}
          />
            <Card>
              <CardHeader title="Cuestionario de Sostenibilidad"
                action={<Button onClick={() => { setOpen(true) }}>Leyenda del cuestionario</Button>}
              />
              <Divider />
              <CardContent>
                <IthQuestionnaire idQuestionnaire={QuestionnaireTypes.Sustainability} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <IthFooter />
    </>
  );
}

export default SustainabilityQuestionnairePage;
