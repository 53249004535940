import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Grid } from '@mui/material';
import IthSimpleDialog from './IthSimpleDialog';

interface IthPageTitleProps {
  heading?: string;
  subHeading?: string;
  buttonText?: string;
  body?: string;
}

const IthPageTitle: FC<IthPageTitleProps> = ({
  heading = '',
  subHeading = '',
  buttonText = '',
  body = '',
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => { setOpen(false); };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <IthSimpleDialog
          open={open}
          title={buttonText}
          body={body}
          onClose={handleClose}
        />

      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {heading}
        </Typography>
        <Typography variant="subtitle2"><div dangerouslySetInnerHTML={{ __html: subHeading }} /></Typography>
        
      </Grid>
      <Grid item>
        {
        (buttonText != '') &&
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => { setOpen(true) }}
            >
              {buttonText}
          </Button>
        }
      </Grid>
    </Grid>
  );
};

IthPageTitle.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  buttonText: PropTypes.string,
  body: PropTypes.string,
};

export default IthPageTitle;
