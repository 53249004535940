import { Helmet } from 'react-helmet-async';

import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material';
import IthPageTitleWrapper from 'src/components/IthPageTitleWrapper';
import IthPageTitle from 'src/components/IthPageTitle';
import IthFooter from 'src/components/IthFooter';
import IthEstablishmentForm from 'src/components/IthEstablishmentForm';


let PRIVACY_POLICY: string = `
<span style="font-size:14px;">
  <p>En virtud de lo establecido en la Reglamento General de Protección de Datos de Carácter Personal,
  el cliente cuyos datos figuran en el presente Formulario, consiente de forma expresa a 
  INSTITUTO TECNOLÓGICO HOTELERO (ITH) el tratamiento de sus datos personales con objeto de realizar 
  facturación y promoción de los servicios que ITH presta, a través de distintas vías, incluyendo el 
  correo electrónico facilitado por el usuario en el presente formulario de inscripción. 
  <br /><br />
  Los datos se incluirán en un fichero automatizado de INSTITUTO TECNOLÓGICO HOTELERO que dispone de las medidas de 
  seguridad necesarias para su confidencialidad y usted podrá ejercitar conforme a la ley sus derechos 
  de acceso, rectificación, cancelación, y oposición dirigiendo un escrito a INSTITUTO TECNOLÓGICO HOTELERO, 
  en C/ ORENSE, 32, 28020, MADRID, o a la dirección de correo electrónico: baja@ithotelero.com</p>
</span>
`;  

function EstablishmentPage() {
  
  return (
    <>
      <Helmet>
        <title>Establecimiento - Datos</title>
      </Helmet>
      <IthPageTitleWrapper>
        <IthPageTitle
          heading="Establecimiento"
          subHeading="En esta sección puede gestionar los datos de su establecimiento"
          buttonText='Política de privacidad'
          body={PRIVACY_POLICY}
        />
      </IthPageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Datos del establecimiento al que representa" />
              <Divider />
              <CardContent>
                <IthEstablishmentForm />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <IthFooter />
    </>
  );
}

export default EstablishmentPage;
