import { useEffect, useState } from 'react';
import { 
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, 
  Button, 
  Container,
  Divider,
  TextField,
  Typography,
  styled, 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import { ContextManager } from 'src/managers/ContextManager';
import IthSimpleDialog from './IthSimpleDialog';
import { ApiManager } from 'src/managers/ApiManager';
import { ApiException, FileResponse, ISustainabilityMemory, SustainabilityMemory } from 'src/api/ApiClient';
import IthLoadDialog from './IthLoadDialog';
import FileSaver from "file-saver";

const DIALOG_TITLE_ERROR : string = "Error";
const DIALOG_TITLE_CREATION_SUCCESS : string = "Creación correcta";
const DIALOG_TITLE_UPDATE_SUCCESS : string = "Actualización correcta";
const DIALOG_BODY_CREATION_SUCCESS : string = "Los datos rellenados en los apartados de la memoria de sostenibilidad se han guardado correctamente.";
const DIALOG_BODY_UPDATE_SUCCESS : string = "Los datos rellenados en los apartados de la memoria de sostenibilidad se han guardado correctamente.";

const DIALOG_TITLE_MEMORY_VOID : string = "Memoria vacia";
const DIALOG_BODY_MEMORY_VOID : string = "Es necesario introducir algún dato para poder descargar la Memoria de Sostenibilidad.";

const IthSustainabilityMemoryForm = (props: {
}) => {
  const [openLoadDialog, setOpenLoadDialog] = useState(false);

  // Dialog info
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string>("")
  const [body, setBody] = useState<string>("")
  const handleClickOpen = (title, body) => { setOpen(true); setTitle(title); setBody(body); };
  const handleClose = () => { setOpen(false); };

  const apiClient = ApiManager.getApiClientInstance();

  // Seccion - Introducción
  const GENERALASPECTS_TITLE: string = "Aspectos generales";
  // const TITLE_INDEPENDENT_ESTABLISHMENT: string = "Memoria de sostenibilidad para hoteles independientes";
  // const TITLE_CHAIN_HOTEL: string = "Memoria de sotenibilidad para cadenas hoteleras";
  // const DESCRIPTION_TITLE: string = "Descripción y propósito del documento";
  // const DESCRIPTION_EXPLANATION: string = `Una memoria de sostenibilidad, también conocida como informe de sostenibilidad o informe de responsabilidad social corporativa (RSC), es un documento que una cadena hotelera o hotel puede elaborar para comunicar de manera transparente sus prácticas y desempeño en aspectos ambientales, sociales y económicos. La estructura de una memoria de sostenibilidad puede variar según la empresa y sus prioridades, pero generalmente sigue una estructura similar. A continuación, encontrará una guía básica donde crear la memoria de sostenibilidad de su establecimiento. `;
  const FRONTPAGE_TITLE: string = "Portada";
  const FRONTPAGE_EXPLANATION: string = `Nombre del hotel, logo, fecha y cualquier otra información relevante.<br/><br/>Se trata de información meramente informativa que deberá añadirse en el momento de la maquetación.`;
  const RESUME_TITLE: string = `Resumen ejecutivo`;
  const RESUME_EXPLANATION: string = `Breve descripción de los aspectos más destacados de la memoria.`;
  const INDEX_TITLE: string = "Índice";
  const INDEX_EXPLANATION: string ="Enumera las secciones y subsecciones del informe. <br/><br/>Se trata de información meramente informativa que deberá añadirse en el momento de la maquetación.";
  const INTRO_TITLE: string = `Introducción`;
  const INTRO_EXPLANATION: string = `Explicar el propósito y alcance de la memoria. Contextualizar la importancia de la sostenibilidad para la cadena hotelera. 
  <br/><br/>Es recomendable introducir en el apartado de introducción un mensaje del CEO. Se trata de una introducción escrita por la alta dirección que destaque el compromiso de la cadena hotelera con la sostenibilidad y la importancia de este informe.`;
  const INTRO_CHAIN_EXPLANATION: string = `*Se recomienda introducir en el discurso información sobre el trabajo de la cadena hotelera respecto a la Responsabilidad Social corporativa.`;

  // Sección - Quienes somos
  const WHOWEARE_TITLE: string = "¿Quienes somos?";
  const WHOWEARE_EXPLANATION: string = "Este apartado busca profundizar en el perfil de la empresa. Descripción, valores, marca, productos.";
  const WHOWEARE_OURHOTEL_TITLE: string = "Nuestro hotel";
  const WHOWEARE_OURHOTEL_CHAIN_TITLE: string = "Nuestros hoteles";
  const WHOWEARE_OURHOTEL_EXPLANATION: string = "Descripción (breve) del hotel. La descripción puede explicar su historia, ubicación, vinculación con el territorio, etc.";
  const WHOWEARE_OURHOTEL_CHAIN_EXPLANATION: string = "Descripción (breve) de los hoteles que forman la cadena hotelera. La descripción puede explicar su historia, ubicación, vinculación con el territorio, etc.";
  const WHOWEARE_MISION_TITLE: string = "Misión, visión, valores";
  const WHOWEARE_MISION_EXPLANATION: string = `Este apartado, da información sobre los objetivos y valores de la empresa. También se puede presentar un código ético, hablar sobre el modelo de negocio, estrategia empresarial y las metas de la empresa.`;
  const WHOWEARE_TECHNICALCHARACTERITICS_TITLE: string = "Tamaño y características técnicas y geográficas del hotel";
  const WHOWEARE_TECHNICALCHARACTERITICS_CHAIN_TITLE: string = "Tamaño y características técnicas y geográficas de los hoteles de la cadena";
  const WHOWEARE_TECHNICALCHARACTERISTICS_EXPLANATION: string = "Tamaño y ubicación, descripción de las instalaciones y servicios. ";
  const WHOWEARE_EMPLOYEES_TITLE: string = "Información sobre el personal y los empleados";
  const WHOWEARE_EMPLOYEES_EXPLANANTION: string = `Incluir en este apartado información sobre el personal del hotel. Descripción y características del equipo profesional del establecimiento. 
  <br/><br/>Presentar el organigrama de la empresa.`;
  const WHOWEARE_EMPLOYEES_CHAIN_EXPLANANTION: string = `Incluir en este apartado información sobre el personal de la cadena hotelera. Descripción y características del equipo profesional del establecimiento. 
  <br/><br/>Presentar el organigrama de la empresa.`;
  const WHOWEARE_GOBERNANCE_TITLE: string = "Gobernanza y Gestión Sostenible";
  const WHOWEARE_GOBERNANCE_EXPLANATION: string = `Estructura de gobierno para asuntos de sostenibilidad. Responsabilidades y roles. Procesos de toma de decisiones relacionados con la sostenibilidad.
  <br/><br/>*Incluir este apartado en la memoria si el establecimiento cuenta con un departamento específico de sostenibilidad o una persona con un cargo específico orientado al desarrollo sostenible del hotel.`;

  // Sección - GRANDES LOGROS 2023 EN EL ÁMBITO DE LA SOSTENIBILIDAD
  const BIGGOALS_TITLE: string = "Grandes logros 2023 en el ámbito de la sostenibilidad";
  const BIGGOALS_EXPLANANTION: string ="Este apartado informa al lector de una forma visual y resumida los principales logros alcanzados, resultados, así como medidas implementadas por el establecimiento hotelero en materia de sostenibilidad. Deben tenerse en cuenta los tres pilares básicos de la sostenibilidad: ambiental, social y económica.";
  const BIGGOALS_CHAIN_EXPLANANTION: string ="Este apartado informa al lector de una forma visual y resumida los principales logros alcanzados, resultados, así como medidas implementadas por la cadena hotelera en materia de sostenibilidad. Deben tenerse en cuenta los tres pilares básicos de la sostenibilidad: ambiental, social y económica.";

  // Sección - SOSTENIBILIDAD AMBIENTAL
  const ENVIRONMENTALSUSTAINABILITY_TITLE: string = "Sostenibilidad ambiental";
  const ENVIRONMENTALSUSTAINABILITY_EXPLANATION: string = "Este apartado, permite mostrar de forma muy esquemática la información relativa a los consumos energéticos, de agua, tratamiento de residuos y todos aquellos campos que puedan generar un impacto de carácter medioambiental. ";
  const ENVIRONMENTALSUSTAINABILITY_EMISSIONS_TITLE: string = "Emisiones";
  const ENVIRONMENTALSUSTAINABILITY_EMISSIONS_EXPLANATION: string = `Las emisiones de dióxido de carbono (CO2) influyen de un modo crucial sobre el cambio climático y la sostenibilidad ambiental. La reducción de emisiones de CO2 en el sector hotelero es una parte importante de los esfuerzos globales para abordar el cambio climático. Medidas como la eficiencia energética, el uso de fuentes de energía renovable, gestión del agua, la correcta gestión de residuos, entre otros, pueden contribuir a una reducción global de los gases de efecto invernadero. 
  <br/><br/>Exponer a continuación el cálculo de la huella de carbono de su establecimiento y argumentar las principales medidas implementadas para su reducción.`;
  const ENVIRONMENTALSUSTAINABILITY_ENERGY_TITLE: string = "Energía";
  const ENVIRONMENTALSUSTAINABILITY_ENERGY_EXPLANATION: string = `Consumo de energía: medir la cantidad de energía utilizada en las instalaciones del hotel y buscar maneras de reducir la huella de carbono a través de la eficiencia energética y la utilización de fuentes de energía renovable.
  <br/><br/>Listado y/o descripción de la implementación de medidas de mejora y eficiencia en materia energética. 
  <br/><br/>Información sobre implementación de fuentes de energía renovable.`;
  const ENVIRONMENTALSUSTAINABILITY_ENERGY_EXPLANATION_MOREINFO: string = `* En el apartado anexos del documento el hotel puede mostrar al detalle los datos de consumo mediante gráficos o tablas.`;
  const ENVIRONMENTALSUSTAINABILITY_ENERGY_CHAIN_EXPLANATION_MOREINFO: string = `* En el apartado anexos del documento se puede mostrar al detalle los datos de consumo mediante gráficos o tablas de los hoteles de la cadena.`;
  const ENVIRONMENTALSUSTAINABILITY_WATER_TITLE: string = "Agua";
  const ENVIRONMENTALSUSTAINABILITY_WATER_EXPLANATION: string = `Gestión del agua: evaluar el consumo de agua del hotel e implementar prácticas para reducir el uso de agua, reutilizar el agua cuando sea posible y gestionar eficientemente las fuentes de agua locales.
  <br/><br/>Listado y/o descripción de la implementación de medidas de mejora y la gestión y consumo del agua.`;
  const ENVIRONMENTALSUSTAINABILITY_WATER_EXPLANATION_MOREINFO: string = `* En el apartado anexos del documento el hotel puede mostrar al detalle los datos de consumo mediante gráficos o tablas.`;
  const ENVIRONMENTALSUSTAINABILITY_WATER_CHAIN_EXPLANATION_MOREINFO: string = `* En el apartado anexos del documento la cadena hotelera puede mostrar al detalle los datos de consumo mediante gráficos o tablas.`;
  const ENVIRONMENTALSUSTAINABILITY_BIODIVERSITY_TITLE: string = "Biodiversidad";
  const ENVIRONMENTALSUSTAINABILITY_BIODIVERSITY_EXPLANATION: string = `Biodiversidad y conservación: listado de prácticas y medidas adoptadas que protejan y preserven la biodiversidad local, incluida la conservación de áreas verdes y la promoción de la flora y fauna autóctonas.`;
  const ENVIRONMENTALSUSTAINABILITY_WASTE_TITLE: string = "Gestión de resíduos y economía circular";
  const ENVIRONMENTALSUSTAINABILITY_WASTE_EXPLANATION: string = `<b>Gestión de residuos:</b> Evaluar la cantidad de residuos generados por el hotel y desarrollar estrategias para reducir, reciclar y gestionar de manera responsable los residuos sólidos y líquidos.
  </br><br/><b>Medidas de economía circular:</b> La economía circular es un modelo económico que busca optimizar el uso de los recursos y reducir al mínimo la generación de residuos. En lugar de seguir el modelo lineal tradicional de "extraer, producir, usar y desechar", la economía circular se centra en diseñar productos de manera sostenible, promover la reutilización, la reparación y el reciclaje, y cerrar los ciclos de vida de los productos y materiales.
  <br/><br/>* Explicar las prácticas y medidas que se implementan a nivel de gestión de residuos y economía circular en el establecimiento hotelero.`;
  const ENVIRONMENTALSUSTAINABILITY_WASTE_CHAIN_EXPLANATION: string = `<b>Gestión de residuos:</b> Evaluar la cantidad de residuos generados por el hotel o la cadena hotelera y desarrollar estrategias para reducir, reciclar y gestionar de manera responsable los residuos sólidos y líquidos.
  </br><br/><b>Medidas de economía circular:</b> La economía circular es un modelo económico que busca optimizar el uso de los recursos y reducir al mínimo la generación de residuos. En lugar de seguir el modelo lineal tradicional de "extraer, producir, usar y desechar", la economía circular se centra en diseñar productos de manera sostenible, promover la reutilización, la reparación y el reciclaje, y cerrar los ciclos de vida de los productos y materiales.
  <br/><br/>* Explicar las prácticas y medidas que se implementan a nivel de gestión de residuos y economía circular en los establecimientos de la cadena hotelera.`;
  
  const ENVIRONMENTALSUSTAINABILITYEXPLANATION_EXTRA: string = `* La cadena hotelera puede presentar los datos de forma global o de forma desglosada dando la información de cada uno de los hoteles de forma individual.`;

  // Sección - SOSTENIBILIDAD SOCIAL
  const SOCIALSUSTAINABILITY_TITLE: string = "Sostenibilidad social";
  const SOCIALSUSTAINABILITY_EXPLANATION: string = "Este apartado muestra las iniciativas y logros adquiridos a nivel social, tanto con el personal laboral del establecimiento, como con los huéspedes. El hotel tiene como responsabilidad Implementar prácticas socialmente responsables, como políticas de empleo éticas, promoción de la diversidad y equidad y contribuciones a causas benéficas.";
  const SOCIALSUSTAINABILITY_CHAIN_EXPLANATION: string = "Este apartado muestra las iniciativas y logros adquiridos a nivel social, tanto con el personal laboral de la cadena hotelera, como con los huéspedes. La cadena hotelera tiene como responsabilidad Implementar prácticas socialmente responsables, como políticas de empleo éticas, promoción de la diversidad y equidad y contribuciones a causas benéficas.";
  const SOCIALSUSTAINABILITY_TEAM_TITLE: string = "Gestión del equipo (implementación de medidas en el entorno laboral)";
  const SOCIALSUSTAINABILITY_TEAM_EXPLANATION: string = ` Medidas implementadas relativas a la igualdad de género.  
  <br/><br/>Medidas implementadas relativas a la formación en el equipo laboral.  
  <br/><br/>Medidas implementadas relativas a la seguridad laboral y prevención de riesgos.`;
  const SOCIALSUSTAINABILITY_TEAM_MOREINFO: string = "* En el apartado anexos del documento el hotel puede mostrar mediante gráficos o tablas los datos a nivel de paridad de equipo, tipos de contratos, promociones internas, entre otros.";
  const SOCIALSUSTAINABILITY_TEAM_CHAIN_MOREINFO: string = "* En el apartado anexos del documento la cadena hotelera puede mostrar mediante gráficos o tablas los datos a nivel de paridad de equipo, tipos de contratos, promociones internas, entre otros.";
  const SOCIALSUSTAINABILITY_TEAM_EXPLANATION_MOREINFO: string = `* En el apartado anexos del documento el hotel puede mostrar mediante gráficos o tablas los datos a nivel de paridad de equipo, tipos de contratos, promociones internas, entre otros.
  <br/><br/>* La cadena hotelera puede presentar los datos de forma global o de forma desglosada dando la información de cada uno de los hoteles de forma individual.`;
  const SOCIALSUSTAINABILITY_EDUCATION_TITLE: string = "Educación y sensibilización";
  const SOCIALSUSTAINABILITY_EDUCATION_EXPLANATION: string = `Implementar programas de educación para empleados y huéspedes sobre prácticas sostenibles, fomentando la conciencia y la participación en la sostenibilidad.
  <br/><br/>Listado y/o descripción de la implementación de medidas de mejora en educación y sensibilización.
  `;
  const SOCIALSUSTAINABILITY_COMMITMENT_TITLE: string = "Compromiso comunitario";
  const SOCIALSUSTAINABILITY_COMMITMENT_EXPLANATION: string = `Desarrollar programas y actividades que beneficien a las comunidades locales, promoviendo el turismo responsable y apoyando proyectos sociales y económicos en la región. Ejemplos: colaboraciones sociales, colaboraciones con instituciones, colaboraciones deportivas, colaboraciones de salud. 
  <br/><br/>Listado y/o descripción de la implementación de programas y actividades en beneficio a la comunidad.
  `;
  const SOCIALSUSTAINABILITY_SUPPLYCHAIN_TITLE: string = "Cadena de suministros";
  const SOCIALSUSTAINABILITY_SUPPLYCHAIN_EXPLANATION: string = `Información sobre tipología de proveedores y empresas colaboradoras. En este espacio es importante destacar los criterios en la selección de los proveedores del establecimiento y garantizar que estos compartan los valores de sostenibilidad de la cadena hotelera, evaluando y monitorizando sus prácticas empresariales.`;

  // Sección - SOSTENIBILIDAD ECONÓMICA
  const ECONOMICSUSTAINABILITY_TITLE: string = "Sostenibilidad económica";
  const ECONOMICSUSTAINABILITY_EXPLANATION: string = `Efectos financieros y comerciales de las prácticas sostenibles implementadas por el establecimiento hotelero. Este apartado pretende ofrecer información sobre las inversiones en sostenibilidad que realiza el establecimiento tales como contribuciones económicas a la comunidad local. Información de interés:
  <br/><br/>Resultados económicos anuales. 
  <br/><br/>Transparencia empresarial. 
  <br/><br/>Indicadores económicos sostenibilidad (inversiones en sostenibilidad, reducción de costes, accesibilidad universal, creación de empleo, entre otros)
  `;

  // Sección - CONTRIBUCIÓN A LOS OBJETIVOS DE DESARROLLOS SOSTENIBLES
  const ODSS_TITLE: string = "Contribución a los objetivos de desarrollo sostenible (ODS)";
  const ODSS_EXPLANATION: string = `Los Objetivos de Desarrollo Sostenible (ODS) son una serie de metas establecidas por las Naciones Unidas para abordar desafíos globales y promover un desarrollo sostenible a nivel mundial. Cada individuo o empresa debe contribuir en la medida de lo posible al cumplimiento de dichos objetivos a fin de alcanzar las metas comunes. 
  la necesidad de colaboración entre gobiernos, sector privado, sociedad civil y ciudadanos para lograr un cambio significativo hacia un desarrollo más sostenible y equitativo.
  <br/><br/>Nombrar aquellos proyectos implementados con relación a los 17 ODS – Agenda 2030.
  <br/><br/>Ejemplo: 
  <br/><br/>ODS 1 – Que medidas ha implementado el hotel o la cadena hotelera para cumplir con la ODS 1 
  <br/><br/>Nombre del proyecto – breve descripción del proyecto. 
  `;

  // Sección - CERTIFICACIONES
  const CERTIFICATIONS_TITLE: string = "Certificaciones";
  const CERTIFICATIONS_EXPLANATION: string = "Indicar si el hotel dispone de certificaciones. Información sobre obtención o procesos de obtención de certificaciones reconocidas internacionalmente, como ISO 14001 (entre otras), que demuestren el compromiso del hotel con la gestión ambiental y la sostenibilidad.";

  // Sección - COMUNICACIÓN Y TRANSPARENCIA
  const COMMUNICATION_TITLE: string = "Comunicación y transparencia";
  const COMMUNICATION_EXPLANATION: string = `Enumerar las medidas y estrategias de comunicación interna y externa sobre sostenibilidad.
  <br/><br/>Mecanismos para la transparencia y divulgación de información.
  `;

  // Sección - INICIATIVAS Y FUTUROS PROYECTOS EN EL AMBITO DE LA SOSTENIBILIDAD
  const INITIATIVE_TITLE: string = "Iniciativas y futuros proyectos en el ámbito de la sostenibilidad";
  const INITIATIVE_EXPLANATION: string = `Planes, proyectos e iniciativas de futuro relacionados con la sostenibilidad.`;

  // Sección - Anexos y documentación de apoyo
  const ANNEXES_TITLE: string = `Anexos y documentación de apoyo`;
  const ANNEXES_EXPLANATION: string = `El apartado de anexos y documentación de apoyo es un espacio adecuado para aportar información de interés que de soporte a la información aportada en la memoria de sostenibilidad. Presentar de forma visual mediante tablas de contenido, gráficos e imágenes los resultados obtenidos de las medidas implementadas y descritas en la memoria de sostenibilidad. 
  <br/><br/>A continuación, se muestran algunos ejemplos de muestras de tablas y gráficas de resultado: 
  <br/><br/>Creación de gráficas comparativa de consumo. 
  <br/><br/>Los siguientes gráficos pretenden mostrar de una forma clara e ilustrativa el impacto directo de las medidas de mejora en materia energética implementadas en los consumos del establecimiento hotelero.
  <br/><br/>Consumo de gas
  <br/><br/><img alt="Consumo gas" id="consumogas" src="/static/images/memory/GraficaConsumoGas.png" />
  <br/><br/>Consumo de electricidad
  <br/><br/><img alt="Consumo electricidad" id="consumoelectricidad" src="/static/images/memory/GraficaConsumoElectricidad.png" />
  <br/><br/>Consumo de energía de fuentes renovables
  <br/><br/><img alt="Consumo Energía Renovable" id="consumoenergrenov" src="/static/images/memory/GraficaConsumoEnergRenov.png" />
  <br/><br/>Ahorro energético por implementación de medidas X 
  <br/><br/><img alt="Ahorro energético" id="ahorroenergetico" src="/static/images/memory/GraficaAhorroEnergetico.png" />
  <br/><br/>Creación de gráfica comparativa de consumo. Los siguientes gráficos pretenden mostrar de una forma clara e ilustrativa el impacto directo de las medidas de mejora en materia de gestión del agua en los consumos del establecimiento hotelero.
  <br/><br/>Gasto de agua
  <br/><br/><img alt="Consumo agua" id="consumoagua" src="/static/images/memory/GraficaGastoAgua.png" />
  <br/><br/>Ahorro de agua por implementación de medidas X
  <br/><br/><img alt="Ahorro agua" id="ahorroagua" src="/static/images/memory/GraficaAhorroAgua.png" />
  `;

  const TypographySection = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(18)};` );
  const TypographySubsection = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(16)};` );
  const TypographyExplanation = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(14)};` );
  const TypographyExplanationMore = styled(Typography)( ({ theme }) => `font-size: ${theme.typography.pxToRem(12)};` );

  // Data input
  const [id, setId] = useState<string>("");
  const [idEstablishment, setIdEstablishment]  = useState<string>("");
  const [resume, setResume] = useState<string>("");
  const [intro, setIntro] = useState<string>("");
  const [hotel, setHotel] = useState<string>("");
  const [mision, setMision] = useState<string>("");
  const [technicalcharacteristics, setTechnicalcharacteristics] = useState<string>("");
  const [employees, setEmployees] = useState<string>("");
  const [gobernance, setGobernance] = useState<string>("");
  const [biggoals, setBiggoals] = useState<string>("");
  const [environmentalsustainability, setEnvironmentalsustainability] = useState<string>("");
  const [emissions, setEmissions] = useState<string>("");
  const [energy, setEnergy] = useState<string>("");
  const [water, setWater] = useState<string>("");
  const [biodiversity, setBiodiversity] = useState<string>("");
  const [circularEconomy, setCircularEconomy] = useState<string>("");
  const [socialSustainability, setSocialSustainability] = useState<string>("");
  const [team, setTeam] = useState<string>("");
  const [education, setEducation] = useState<string>("");
  const [commitment, setCommitment] = useState<string>("");
  const [supplychain, setSupplychain] = useState<string>("");
  const [economic, setEconomic] = useState<string>("");
  const [odss, setOdss] = useState<string>("");
  const [certifications, setCertifications] = useState<string>("");
  const [communication, setCommunication] = useState<string>("");
  const [initiative, setInitiative] = useState<string>("");

  useEffect(() => {
    setIdEstablishment(ContextManager.establishment.id);

    if(ContextManager.establishment.sustainabilityMemories.length != 0) {
      let currentMemory: SustainabilityMemory = ContextManager.establishment.sustainabilityMemories[0];
      setId(currentMemory.id);
      setResume(currentMemory.resume);
      setIntro(currentMemory.intro);
      setHotel(currentMemory.ourHotel);
      setMision(currentMemory.mision);
      setTechnicalcharacteristics(currentMemory.technicalCharacteristics);
      setEmployees(currentMemory.employeeInfo);
      setGobernance(currentMemory.gobernance);
      setBiggoals(currentMemory.bigGoals);
      setEnvironmentalsustainability(currentMemory.environmentalSustainability);
      setEmissions(currentMemory.emissions);
      setEnergy(currentMemory.energy);
      setWater(currentMemory.water);
      setBiodiversity(currentMemory.biodiversity);
      setCircularEconomy(currentMemory.circularEconomy);
      setSocialSustainability(currentMemory.socialSustainability);
      setTeam(currentMemory.teamManagement);
      setEducation(currentMemory.educationSensibility);
      setCommitment(currentMemory.comunityCommitment);
      setSupplychain(currentMemory.supplyChain);
      setEconomic(currentMemory.economicSustainability);
      setOdss(currentMemory.odssContribution);
      setCertifications(currentMemory.certifications);
      setCommunication(currentMemory.communication);
      setInitiative(currentMemory.initiatives);
    }
    else {
      setId(ContextManager.getGUID());
    }
  }, []);

  const safeEstablishmentData = async (event) => {
    event.preventDefault();

    setOpenLoadDialog(true);

    let iSustainabilityMemory: ISustainabilityMemory = {
      id: id,
      idEstablishment: idEstablishment,
      resume: resume,
      intro: intro,
      ourHotel: hotel,
      mision: mision,
      technicalCharacteristics: technicalcharacteristics,
      employeeInfo: employees,
      gobernance: gobernance,
      bigGoals: biggoals,
      environmentalSustainability: environmentalsustainability,
      emissions: emissions,
      energy: energy,
      water: water,
      biodiversity: biodiversity,
      circularEconomy: circularEconomy,
      socialSustainability: socialSustainability,
      teamManagement: team,
      educationSensibility: education,
      comunityCommitment: commitment,
      supplyChain: supplychain,
      economicSustainability: economic,
      odssContribution: odss,
      certifications: certifications,
      communication: communication,
      initiatives: initiative
    };

    let action: Promise<boolean> = null;
    let memory: SustainabilityMemory = new SustainabilityMemory(iSustainabilityMemory);
    if(ContextManager.establishment.sustainabilityMemories.length == 0) {
      action = apiClient.apiSustainabilityMemoryAdd(memory);
    }
    else {
      action = apiClient.apiSustainabilityMemoryUpdate(memory);
    }

    action.then((data: boolean) => {
      if(ContextManager.establishment.sustainabilityMemories.length == 0) {
        ContextManager.establishment.sustainabilityMemories.push(memory);
        handleClickOpen(DIALOG_TITLE_CREATION_SUCCESS, DIALOG_BODY_CREATION_SUCCESS);
      }
      else 
      {
        ContextManager.establishment.sustainabilityMemories[0] = memory;
        handleClickOpen(DIALOG_TITLE_UPDATE_SUCCESS, DIALOG_BODY_UPDATE_SUCCESS);
      }
    })
    .catch((e: ApiException) => {
      handleClickOpen(DIALOG_TITLE_ERROR, e.response);
    })
    .finally(() => {
      setOpenLoadDialog(false);
    });
      
  };

  /*
   * Generate Doc
   */
  async function generateDoc(): Promise<void> {
    if( ((resume == null) || (resume == "")) &&
        ((intro == null) || (intro == "")) &&
        ((hotel == null) || (hotel == "")) &&
        ((mision == null) || (mision == "")) &&
        ((technicalcharacteristics == null) || (technicalcharacteristics == "")) &&
        ((employees == null) || (employees == "")) &&
        ((gobernance == null) || (gobernance == "")) &&
        ((environmentalsustainability == null) || (environmentalsustainability == "")) &&
        ((biggoals == null) || (biggoals == "")) &&
        ((emissions == null) || (emissions == "")) &&
        ((energy == null) || (energy == "")) &&
        ((water == null) || (water == "")) &&
        ((biodiversity == null) || (biodiversity == "")) &&
        ((circularEconomy == null) || (circularEconomy == "")) &&
        ((socialSustainability == null) || (socialSustainability == "")) &&
        ((team == null) || (team == "")) &&
        ((education == null) || (education == "")) &&
        ((commitment == null) || (commitment == "")) &&
        ((supplychain == null) || (supplychain == "")) &&
        ((economic == null) || (economic == "")) &&
        ((odss == null) || (odss == "")) &&
        ((certifications == null) || (certifications == "")) &&
        ((communication == null) || (communication == "")) &&
        ((initiative == null) || (initiative == ""))
      ) {
        handleClickOpen(DIALOG_TITLE_MEMORY_VOID, DIALOG_BODY_MEMORY_VOID);
    }
    else {
      setOpenLoadDialog(true);
      apiClient.apiSustainabilityMemoryDownloadSustainabilityMemory()
      .then((fileResponse: FileResponse) => {
        FileSaver.saveAs(fileResponse.data, "MemoriaSostenibilidad.docx");
      })
      .catch((e: ApiException) => {
        handleClickOpen(DIALOG_TITLE_ERROR, e.response);
      })
      .finally(() => {
        setOpenLoadDialog(false);
      });
    }
  }

  return (
    <Container>
      <IthLoadDialog open={openLoadDialog} />
      <IthSimpleDialog
        open={open}
        title={title}
        body={body}
        onClose={handleClose}
      />
      
      <Button
        variant="contained"
        sx={{ mb: 2, justifyContent: "auto" }}
        onClick={generateDoc}
        endIcon={<SendIcon />}
        >
          Generar Memoria
      </Button>

      <Box 
          component="form" 
          onSubmit={safeEstablishmentData} 
          noValidate
      >
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <TypographySection variant="h3">{GENERALASPECTS_TITLE}</TypographySection>
          </AccordionSummary>
          <AccordionDetails>
            <TypographySubsection variant="h4">{FRONTPAGE_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: FRONTPAGE_EXPLANATION }} /></TypographyExplanation>
            <br/><br/>

            <TypographySubsection variant="h4">{RESUME_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: RESUME_EXPLANATION }} /></TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="resume" 
              name="resume" 
              label={RESUME_TITLE}
              autoComplete="resume"
              value={resume} onChange={(e) => setResume(e.target.value)}
            />
            <br/><br/>

            <TypographySubsection variant="h4">{INDEX_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: INDEX_EXPLANATION }} /></TypographyExplanation>
            <br/><br/>
            
            <TypographySubsection variant="h4">{INTRO_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: INTRO_EXPLANATION }} /></TypographyExplanation>
            {
              (ContextManager.establishment.idEstablishmentType == 1) &&
              <div>
                <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: INTRO_CHAIN_EXPLANATION }} /></TypographyExplanation>
              </div>
              
            }
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="intro" 
              name="intro" 
              label={INTRO_TITLE}
              autoComplete="intro"
              value={intro} onChange={(e) => setIntro(e.target.value)}
            />
            <br/><br/>
          </AccordionDetails>
        </Accordion>
        <Divider />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <TypographySection variant="h3">{WHOWEARE_TITLE}</TypographySection>
          </AccordionSummary>
          <AccordionDetails>
            <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: WHOWEARE_EXPLANATION }} /></TypographyExplanation>
            <br/><br/> 

            <TypographySubsection variant="h4">
              {(ContextManager.establishment.idEstablishmentType == 1) ? WHOWEARE_OURHOTEL_CHAIN_TITLE : WHOWEARE_OURHOTEL_TITLE}
            </TypographySubsection>
            <br/><TypographyExplanation>
              <div dangerouslySetInnerHTML={{ __html: 
                (ContextManager.establishment.idEstablishmentType == 1) ? WHOWEARE_OURHOTEL_CHAIN_EXPLANATION : WHOWEARE_OURHOTEL_EXPLANATION
              }} />
            </TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="hotel" 
              name="hotel" 
              label={WHOWEARE_OURHOTEL_TITLE}
              autoComplete="hotel"
              value={hotel} onChange={(e) => setHotel(e.target.value)}
            />
            <br/><br/>

            <TypographySubsection variant="h4">{WHOWEARE_MISION_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: WHOWEARE_MISION_EXPLANATION }} /></TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="mision" 
              name="mision" 
              label={WHOWEARE_MISION_TITLE}
              autoComplete="mision"
              value={mision} onChange={(e) => setMision(e.target.value)}
            />
            <br/><br/>

            <TypographySubsection variant="h4">
              {(ContextManager.establishment.idEstablishmentType == 1) ? WHOWEARE_TECHNICALCHARACTERITICS_CHAIN_TITLE : WHOWEARE_TECHNICALCHARACTERITICS_TITLE}
            </TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: WHOWEARE_TECHNICALCHARACTERISTICS_EXPLANATION }} /></TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="technicalcharacteristics" 
              name="technicalcharacteristics" 
              label={WHOWEARE_TECHNICALCHARACTERITICS_TITLE}
              autoComplete="technicalcharacteristics"
              value={technicalcharacteristics} onChange={(e) => setTechnicalcharacteristics(e.target.value)}
            />
            <br/><br/>

            <TypographySubsection variant="h4">{WHOWEARE_EMPLOYEES_TITLE}</TypographySubsection>
            <br/><TypographyExplanation>
              <div dangerouslySetInnerHTML={{ __html: 
                (ContextManager.establishment.idEstablishmentType == 1) ? WHOWEARE_EMPLOYEES_CHAIN_EXPLANANTION : WHOWEARE_EMPLOYEES_EXPLANANTION 
              }} />
            </TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="employees" 
              name="employees" 
              label={WHOWEARE_EMPLOYEES_TITLE}
              autoComplete="employees"
              value={employees} onChange={(e) => setEmployees(e.target.value)}
            />
            <br/><br/>

            <TypographySubsection variant="h4">{WHOWEARE_GOBERNANCE_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: WHOWEARE_GOBERNANCE_EXPLANATION }} /></TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="gobernance" 
              name="gobernance" 
              label={WHOWEARE_GOBERNANCE_TITLE}
              autoComplete="gobernance"
              value={gobernance} onChange={(e) => setGobernance(e.target.value)}
            />
            <br/><br/>

          </AccordionDetails>
        </Accordion>
        <Divider />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <TypographySection variant="h3">{BIGGOALS_TITLE}</TypographySection>
          </AccordionSummary>
          <AccordionDetails>
            <TypographyExplanation>
              <div dangerouslySetInnerHTML={{ __html: 
                (ContextManager.establishment.idEstablishmentType == 1) ? BIGGOALS_CHAIN_EXPLANANTION : BIGGOALS_EXPLANANTION 
              }} />
              </TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="biggoals" 
              name="biggoals" 
              label={BIGGOALS_TITLE}
              autoComplete="biggoals"
              value={biggoals} onChange={(e) => setBiggoals(e.target.value)}
            />
            <br/><br/>
            
          </AccordionDetails>
        </Accordion>
        <Divider />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <TypographySection variant="h3">{ENVIRONMENTALSUSTAINABILITY_TITLE}</TypographySection>
          </AccordionSummary>
          <AccordionDetails>
            <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITY_EXPLANATION }} /></TypographyExplanation>
            {/* <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="environmentalsustainability" 
              name="environmentalsustainability" 
              label={ENVIRONMENTALSUSTAINABILITY_TITLE}
              autoComplete="environmentalsustainability"
              value={environmentalsustainability} onChange={(e) => setEnvironmentalsustainability(e.target.value)}
            /> */}
            <br/><br/>

            <TypographySubsection variant="h4">{ENVIRONMENTALSUSTAINABILITY_EMISSIONS_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITY_EMISSIONS_EXPLANATION }} /></TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="emissions" 
              name="emissions" 
              label={ENVIRONMENTALSUSTAINABILITY_EMISSIONS_TITLE}
              autoComplete="emissions"
              value={emissions} onChange={(e) => setEmissions(e.target.value)}
            />
            <br/><br/>

            <TypographySubsection variant="h4">{ENVIRONMENTALSUSTAINABILITY_ENERGY_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITY_ENERGY_EXPLANATION }} /></TypographyExplanation>
            {
              (ContextManager.establishment.idEstablishmentType != 1) &&
              <div>
                <br/><TypographyExplanationMore><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITY_ENERGY_EXPLANATION_MOREINFO }} /></TypographyExplanationMore>
              </div>
            }
            {
              (ContextManager.establishment.idEstablishmentType == 1) &&
              <div>
                <br/>
                <TypographyExplanationMore><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITY_ENERGY_CHAIN_EXPLANATION_MOREINFO }} /></TypographyExplanationMore>
                <TypographyExplanationMore><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITYEXPLANATION_EXTRA }} /></TypographyExplanationMore>
              </div>
            }

            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="energy" 
              name="energy" 
              label={ENVIRONMENTALSUSTAINABILITY_ENERGY_TITLE}
              autoComplete="energy"
              value={energy} onChange={(e) => setEnergy(e.target.value)}
            />
            <br/><br/>

            <TypographySubsection variant="h4">{ENVIRONMENTALSUSTAINABILITY_WATER_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITY_WATER_EXPLANATION }} /></TypographyExplanation>
            {
              (ContextManager.establishment.idEstablishmentType != 1) &&
              <div>
                <br/><TypographyExplanationMore><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITY_WATER_EXPLANATION_MOREINFO }} /></TypographyExplanationMore>
              </div>
            }
            {
              (ContextManager.establishment.idEstablishmentType == 1) &&
              <div>
                <br/>
                <TypographyExplanationMore><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITY_WATER_CHAIN_EXPLANATION_MOREINFO }} /></TypographyExplanationMore>
                <TypographyExplanationMore><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITYEXPLANATION_EXTRA }} /></TypographyExplanationMore>
              </div>
            }
            
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="water" 
              name="water" 
              label={ENVIRONMENTALSUSTAINABILITY_WATER_TITLE}
              autoComplete="water"
              value={water} onChange={(e) => setWater(e.target.value)}
            />
            <br/><br/>

            <TypographySubsection variant="h4">{ENVIRONMENTALSUSTAINABILITY_BIODIVERSITY_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITY_BIODIVERSITY_EXPLANATION }} /></TypographyExplanation>
            {
              (ContextManager.establishment.idEstablishmentType == 1) &&
              <div>
                <br/>
                <TypographyExplanationMore><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITY_WATER_CHAIN_EXPLANATION_MOREINFO }} /></TypographyExplanationMore>
                <TypographyExplanationMore><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITYEXPLANATION_EXTRA }} /></TypographyExplanationMore>
              </div>
            }
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="biodiversity" 
              name="biodiversity" 
              label={ENVIRONMENTALSUSTAINABILITY_BIODIVERSITY_TITLE}
              autoComplete="biodiversity"
              value={biodiversity} onChange={(e) => setBiodiversity(e.target.value)}
            />
            <br/><br/>

            <TypographySubsection variant="h4">{ENVIRONMENTALSUSTAINABILITY_WASTE_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: 
              (ContextManager.establishment.idEstablishmentType == 1) ? ENVIRONMENTALSUSTAINABILITY_WASTE_CHAIN_EXPLANATION : ENVIRONMENTALSUSTAINABILITY_WASTE_EXPLANATION 
              }} />
            </TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="circularEconomy" 
              name="circularEconomy" 
              label={ENVIRONMENTALSUSTAINABILITY_WASTE_TITLE}
              autoComplete="circularEconomy"
              value={circularEconomy} onChange={(e) => setCircularEconomy(e.target.value)}
            />
            <br/><br/>


          </AccordionDetails>
        </Accordion>
        <Divider />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <TypographySection variant="h3">{SOCIALSUSTAINABILITY_TITLE}</TypographySection>
          </AccordionSummary>
          <AccordionDetails>
            <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: 
              (ContextManager.establishment.idEstablishmentType == 1) ? SOCIALSUSTAINABILITY_CHAIN_EXPLANATION : SOCIALSUSTAINABILITY_EXPLANATION 
            }} />
            </TypographyExplanation>
            <br/><TypographyExplanationMore><div dangerouslySetInnerHTML={{ __html: SOCIALSUSTAINABILITY_TEAM_EXPLANATION_MOREINFO }} /></TypographyExplanationMore>
            
            {/* <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="socialsustainability" 
              name="socialsustainability" 
              label={SOCIALSUSTAINABILITY_TEAM_TITLE}
              autoComplete="socialsustainability"
              value={socialSustainability} onChange={(e) => setSocialSustainability(e.target.value)}
            /> */}
            <br/><br/> 

            <TypographySubsection variant="h4">{SOCIALSUSTAINABILITY_TEAM_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: SOCIALSUSTAINABILITY_TEAM_EXPLANATION}} /></TypographyExplanation>
            {
              (ContextManager.establishment.idEstablishmentType != 1) &&
              <div>
                <br/><TypographyExplanationMore><div dangerouslySetInnerHTML={{ __html: SOCIALSUSTAINABILITY_TEAM_MOREINFO }} /></TypographyExplanationMore>
              </div>
            }
            {
              (ContextManager.establishment.idEstablishmentType == 1) &&
              <div>
                <br/>
                <TypographyExplanationMore><div dangerouslySetInnerHTML={{ __html: SOCIALSUSTAINABILITY_TEAM_CHAIN_MOREINFO }} /></TypographyExplanationMore>
                <TypographyExplanationMore><div dangerouslySetInnerHTML={{ __html: ENVIRONMENTALSUSTAINABILITYEXPLANATION_EXTRA }} /></TypographyExplanationMore>
              </div>
            }
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="team" 
              name="team" 
              label={SOCIALSUSTAINABILITY_TEAM_TITLE}
              autoComplete="team"
              value={team} onChange={(e) => setTeam(e.target.value)}
            />
            <br/><br/>

            <TypographySubsection variant="h4">{SOCIALSUSTAINABILITY_EDUCATION_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: SOCIALSUSTAINABILITY_EDUCATION_EXPLANATION }} /></TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="education" 
              name="education" 
              label={SOCIALSUSTAINABILITY_EDUCATION_TITLE}
              autoComplete="education"
              value={education} onChange={(e) => setEducation(e.target.value)}
            />
            <br/><br/>

            <TypographySubsection variant="h4">{SOCIALSUSTAINABILITY_COMMITMENT_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: SOCIALSUSTAINABILITY_COMMITMENT_EXPLANATION }} /></TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="commitment" 
              name="commitment" 
              label={SOCIALSUSTAINABILITY_COMMITMENT_TITLE}
              autoComplete="commitment"
              value={commitment} onChange={(e) => setCommitment(e.target.value)}
            />
            <br/><br/>

            <TypographySubsection variant="h4">{SOCIALSUSTAINABILITY_SUPPLYCHAIN_TITLE}</TypographySubsection>
            <br/><TypographyExplanation><div dangerouslySetInnerHTML={{ __html: SOCIALSUSTAINABILITY_SUPPLYCHAIN_EXPLANATION }} /></TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="supplychain" 
              name="supplychain" 
              label={SOCIALSUSTAINABILITY_SUPPLYCHAIN_TITLE}
              autoComplete="supplychain"
              value={supplychain} onChange={(e) => setSupplychain(e.target.value)}
            />
            <br/><br/>

          </AccordionDetails>
        </Accordion>
        <Divider />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <TypographySection variant="h3">{ECONOMICSUSTAINABILITY_TITLE}</TypographySection>
          </AccordionSummary>
          <AccordionDetails>
            <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: ECONOMICSUSTAINABILITY_EXPLANATION }} /></TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="economic" 
              name="economic" 
              label={ECONOMICSUSTAINABILITY_TITLE}
              autoComplete="economic"
              value={economic} onChange={(e) => setEconomic(e.target.value)}
            />
            <br/><br/>

          </AccordionDetails>
        </Accordion>
        <Divider />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <TypographySection variant="h3">{ODSS_TITLE}</TypographySection>
          </AccordionSummary>
          <AccordionDetails>
            <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: ODSS_EXPLANATION }} /></TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="odss" 
              name="odss" 
              label={ODSS_TITLE}
              autoComplete="odss"
              value={odss} onChange={(e) => setOdss(e.target.value)}
            />
            <br/><br/>

          </AccordionDetails>
        </Accordion>
        <Divider />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <TypographySection variant="h3">{CERTIFICATIONS_TITLE}</TypographySection>
          </AccordionSummary>
          <AccordionDetails>
            <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: CERTIFICATIONS_EXPLANATION }} /></TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="certifications" 
              name="certifications" 
              label={CERTIFICATIONS_TITLE}
              autoComplete="certifications"
              value={certifications} onChange={(e) => setCertifications(e.target.value)}
            />
            <br/><br/>

          </AccordionDetails>
        </Accordion>
        <Divider />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <TypographySection variant="h3">{COMMUNICATION_TITLE}</TypographySection>
          </AccordionSummary>
          <AccordionDetails>
            <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: COMMUNICATION_EXPLANATION }} /></TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="communication" 
              name="communication" 
              label={COMMUNICATION_TITLE}
              autoComplete="communication"
              value={communication} onChange={(e) => setCommunication(e.target.value)}
            />
            <br/><br/>

          </AccordionDetails>
        </Accordion>
        <Divider />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <TypographySection variant="h3">{INITIATIVE_TITLE}</TypographySection>
          </AccordionSummary>
          <AccordionDetails>
            <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: INITIATIVE_EXPLANATION }} /></TypographyExplanation>
            <TextField
              margin="normal"
              multiline 
              required 
              fullWidth
              id="initiative" 
              name="initiative" 
              label={INITIATIVE_TITLE}
              autoComplete="initiative"
              value={initiative} onChange={(e) => setInitiative(e.target.value)}
            />
            <br/><br/>

          </AccordionDetails>
        </Accordion>
        <Divider />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <TypographySection variant="h3">{ANNEXES_TITLE}</TypographySection>
          </AccordionSummary>
          <AccordionDetails>
            <TypographyExplanation><div dangerouslySetInnerHTML={{ __html: ANNEXES_EXPLANATION }} /></TypographyExplanation>
            <br/><br/>
          </AccordionDetails>
        </Accordion>
        <Divider />
        <Box
          m={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Guardar
          </Button>
        </Box>
        
      </Box>
      
    </Container>

    
  );
}

export default IthSustainabilityMemoryForm;
