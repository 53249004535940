import { ApiClient } from "../api/ApiClient";
import { EnvironmentManager } from "./EnvironmentManager";

export class ApiManager {
    private static instance: ApiClient;

    /**
     * The purpose of this class is avoid include the singleton pattern inside the ApiClient file,
     * because the content of the file is create by NswagStudio using the swagger specifications
     * of our API, so if we make changes in the API and we have to recreate the ApiClient, 
     * we just replace the current content with the new one generated with NswagStudio
     */
    public static getApiClientInstance(): ApiClient {
        if (!ApiManager.instance) {
            ApiManager.instance = new ApiClient(EnvironmentManager.GetApiUrl(), null);
        }

        return ApiManager.instance;
    }
}