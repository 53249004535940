export enum EnvironmentEnum {
    Local = 1,
    Pre = 2,
    Pro = 3
}

export class EnvironmentManager {

    public static Environment: EnvironmentEnum;

    // Web
    public static WebFrontUrlLocal = "http://localhost:3000/";

    public static WebFrontUrlPre = "https://happy-island-006326003.4.azurestaticapps.net/";

    public static WebFrontUrlPro = "https://white-meadow-0b46cbb03.5.azurestaticapps.net/";

    public static WebFrontUrlPro2 = "https://testsostenibilidad.ithotelero.com/"

    // Api
    public static ApiUrlLocal: string = "https://localhost:5001";

    public static ApiUrlPre: string = "https://ithquestionnairesback.azurewebsites.net";

    public static ApiUrlPro: string = "https://sustainabilitybackas01.azurewebsites.net";

    public static SetEnvironment(webUrl: string): void {
        if(webUrl == EnvironmentManager.WebFrontUrlLocal) EnvironmentManager.Environment = EnvironmentEnum.Local;
        else if(webUrl == EnvironmentManager.WebFrontUrlPre) EnvironmentManager.Environment = EnvironmentEnum.Pre;
        else if((webUrl == EnvironmentManager.WebFrontUrlPro) || (webUrl == EnvironmentManager.WebFrontUrlPro2)) EnvironmentManager.Environment = EnvironmentEnum.Pro;
    } 

    public static GetApiUrl(): string {
        let url: string = "";
        switch(EnvironmentManager.Environment) {
            case EnvironmentEnum.Pre: url = EnvironmentManager.ApiUrlPre; break;
            case EnvironmentEnum.Pro: url = EnvironmentManager.ApiUrlPro; break;
            default: url = EnvironmentManager.ApiUrlLocal;
        }
        return url;
    }

}