import { Helmet } from 'react-helmet-async';

import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material';
import IthPageTitleWrapper from 'src/components/IthPageTitleWrapper';
import IthPageTitle from 'src/components/IthPageTitle';
import IthFooter from 'src/components/IthFooter';
import { QuestionnaireTypes } from 'src/constants/QuestionnaireTypes';
import IthBenchmarkDashboard from 'src/components/IthBenchmarkDashboard';

const TEXT_TITLE: string = "Informe de Benchmarking";
const TEXT_SUBTITLE: string = "En esta sección podrá ver un informe comparativo con unn hotel de referencia, así con el resto de hoteles por tipología y localización.";

function FoodwasteBenchmarkingPage() {
  
  return (
    <>
      <Helmet>
        <title>Desperdicio de alimento - Benchmarking</title>
      </Helmet>
      <IthPageTitleWrapper>
        <IthPageTitle
          heading={TEXT_TITLE}
          subHeading={TEXT_SUBTITLE}
          buttonText=''
          body=''
          
        />
      </IthPageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title={TEXT_TITLE} />
              <Divider />
              <CardContent>
                <IthBenchmarkDashboard idQuestionnaire={QuestionnaireTypes.FoodWaste}></IthBenchmarkDashboard>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <IthFooter />
    </>
  );
}

export default FoodwasteBenchmarkingPage;
