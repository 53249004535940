export class QuestionnaireTypes {
    
    /*
     * Public properties
     */
    public static Covid19: string = "00000000-0000-0000-0000-000000000001";

    public static Sustainability: string = "00000000-0000-0000-0000-000000000002";

    public static FoodWaste: string = "00000000-0000-0000-0000-000000000003";

}